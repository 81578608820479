import { Collection } from 'vue-mc'
import AanmeldOptie from '../models/AanmeldOptie'
import _ from 'lodash'

export default class AanmeldOptieCollection extends Collection {

    model() {
        return AanmeldOptie;
    }

    /**
     * Returns the number of completed symposiums (saved state).
     */
    get completed() {
        return this.sum((aanmeld_optie) => aanmeld_optie.$.done);
    }

    /**
     * Returns the percentage of completed symposiums.
     */
    get progress() {
        if (this.isEmpty()) {
            return 0;
        }

        return _.round(this.completed / this.length * 100);
    }

    routes() {
        return {
            fetch: '/aanmeld_opties/{symposium_id}',
        }
    }

    fill(id){
        this.fetch({
          url : '/aanmeld_opties/'+id
        });
    }
}