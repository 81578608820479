import { Model } from 'vue-mc'
import { required, length } from 'vue-mc/validation'

export default class Participant extends Model {

    defaults() {
        return {
            id: null,
            name: '',
            current: false,
            logged_in: false,
            factuur: true,
        }
    }

    options() {
        return {
            useFirstErrorOnly: true,
        }
    }

    validation() {
        return {

        }
    }

    mutations() {
        return {
        }
    }

    routes() {
        return {

        }
    }
}
