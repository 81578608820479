<template>
    
</template>

<script>
    import AanmeldingModalMethods from '../methods/AanmeldingModalMethods';
    export default {
        mounted() {
            console.log('init Login');
            console.log(this.symposium_id);
            this.gotoLogin();
        },
        methods: {
            gotoLogin: function () {
                let currentObj = this;
                AanmeldingModalMethods.gotoLogin(currentObj);
            }
        },
        computed: {
            symposium_id: function(){
                let currentObj = this;
                return currentObj.$route.query.symposium_id;
            }
        }
    }
</script>