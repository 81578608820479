<template>
  <div class="card-body p-4">
    <ErrorToast :id="error_toast_id" :error_messages="error_messages"></ErrorToast>
    <SuccessToast :id="success_toast_id" :success_messages="success_messages"></SuccessToast>
    <div class="flex justify-between"><h3 v-if="!edit" class="mb-3">{{ $t('aanmelding.Uw gegevens') }} </h3><div v-if="!edit" class="cursor-pointer" @click="setEdit(true)"><i class="mdi mdi-account-edit text-lg mr-2"></i>{{ $t('mijn_gegevens.wijzigen')}}</div></div>

    <table class="table-auto" v-if="!edit">
      <tbody>
      <tr>
        <td class="pr-3">{{ $t('mijn_gegevens.Voorletters')}}</td>
        <td>{{ initials }}</td>
      </tr>
      <tr>
        <td class="pr-3">{{ $t('mijn_gegevens.Voornaam')}}</td>
        <td>{{ first_name }}</td>
      </tr>
      <tr>
        <td class="pr-3">{{ $t('mijn_gegevens.Tussenvoegsel')}}</td>
        <td>{{ insertion }}</td>
      </tr>
      <tr>
        <td class="pr-3">{{ $t('mijn_gegevens.Achternaam')}}</td>
        <td>{{ last_name }}</td>
      </tr>
      <tr>
        <td class="pr-3">{{ $t('mijn_gegevens.Achtervoegsel')}}</td>
        <td>{{ suffix }}</td>
      </tr>
      <tr>
        <td class="pr-3">{{ $t('mijn_gegevens.Bedrijfsnaam')}}</td>
        <td>{{ company_name }}</td>
      </tr>
      <tr>
        <td class="pr-3">{{ $t('mijn_gegevens.Adres')}}</td>
        <td>{{ address }}</td>
      </tr>
      <tr>
        <td class="pr-3">{{ $t('mijn_gegevens.Postcode')}}</td>
        <td>{{ zip_code }}</td>
      </tr>
      <tr>
        <td class="pr-3">{{ $t('mijn_gegevens.Plaats')}}</td>
        <td>{{ city }}</td>
      </tr>
      <tr>
        <td class="pr-3">{{ $t('mijn_gegevens.Mobiel')}}</td>
        <td>{{ mobile }}</td>
      </tr>
      </tbody>
    </table>
    <form v-if="edit" @submit="formSubmitEditUser" novalidate>


      <div class="md:flex mb-6">
        <div class="md:w-1/3">
          <label class="block  font-bold md:text-left mb-3 md:mb-0 pr-4" for="initials">{{ $t('mijn_gegevens.Voorletters')}}</label>
        </div>
        <div class="md:w-2/3">
          <input class="form-input block w-full focus:bg-white" id="initials" type="text" v-model="initials">
        </div>
      </div>
      <div class="md:flex mb-6">
        <div class="md:w-1/3">
          <label class="block  font-bold md:text-left mb-3 md:mb-0 pr-4" for="first_name">{{ $t('mijn_gegevens.Voornaam')}}</label>
        </div>
        <div class="md:w-2/3">
          <input class="form-input block w-full focus:bg-white" id="first_name" type="text" v-model="first_name">
        </div>
      </div>
      <div class="md:flex mb-6">
        <div class="md:w-1/3">
          <label class="block  font-bold md:text-left mb-3 md:mb-0 pr-4" for="insertion">{{ $t('mijn_gegevens.Tussenvoegsel')}}</label>
        </div>
        <div class="md:w-2/3">
          <input class="form-input block w-full focus:bg-white" id="insertion" type="text" v-model="insertion">
        </div>
      </div>
      <div class="md:flex mb-6">
        <div class="md:w-1/3">
          <label class="block  font-bold md:text-left mb-3 md:mb-0 pr-4" for="last_name">{{ $t('mijn_gegevens.Achternaam')}}</label>
        </div>
        <div class="md:w-2/3">
          <input class="form-input block w-full focus:bg-white" id="last_name" type="text" v-model="last_name">
        </div>
      </div>
      <div class="md:flex mb-6">
        <div class="md:w-1/3">
          <label class="block  font-bold md:text-left mb-3 md:mb-0 pr-4" for="suffix">{{ $t('mijn_gegevens.Achtervoegsel')}}</label>
        </div>
        <div class="md:w-2/3">
          <input class="form-input block w-full focus:bg-white" id="suffix" type="text" v-model="suffix">
        </div>
      </div>
      <div class="md:flex mb-6">
        <div class="md:w-1/3">
          <label class="block  font-bold md:text-left mb-3 md:mb-0 pr-4" for="company_name">{{ $t('mijn_gegevens.Bedrijfsnaam')}}</label>
        </div>
        <div class="md:w-2/3">
          <input class="form-input block w-full focus:bg-white" id="company_name" type="text" v-model="company_name">
        </div>
      </div>
      <div class="md:flex mb-6">
        <div class="md:w-1/3">
          <label class="block  font-bold md:text-left mb-3 md:mb-0 pr-4" for="address">{{ $t('mijn_gegevens.Adres')}}</label>
        </div>
        <div class="md:w-2/3">
          <input class="form-input block w-full focus:bg-white" id="address" type="text" v-model="address">
        </div>
      </div>
      <div class="md:flex mb-6">
        <div class="md:w-1/3">
          <label class="block  font-bold md:text-left mb-3 md:mb-0 pr-4" for="zip_code">{{ $t('mijn_gegevens.Postcode')}}</label>
        </div>
        <div class="md:w-2/3">
          <input class="form-input block w-full focus:bg-white" id="zip_code" type="text" v-model="zip_code">
        </div>
      </div>
      <div class="md:flex mb-6">
        <div class="md:w-1/3">
          <label class="block  font-bold md:text-left mb-3 md:mb-0 pr-4" for="city">{{ $t('mijn_gegevens.Plaats')}}</label>
        </div>
        <div class="md:w-2/3">
          <input class="form-input block w-full focus:bg-white" id="city" type="text" v-model="city">
        </div>
      </div>
      <div class="md:flex mb-6">
        <div class="md:w-1/3">
          <label class="block  font-bold md:text-left mb-3 md:mb-0 pr-4" for="mobile">{{ $t('mijn_gegevens.Mobiel')}}</label>
        </div>
        <div class="md:w-2/3">
          <input class="form-input block w-full focus:bg-white" id="mobile" type="text" v-model="mobile">
        </div>
      </div>

      <div class="md:flex md:items-center">
        <div class="md:w-1/3"></div>
        <div class="md:w-2/3">
          <button class="inline-block rounded bg-project-default px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200 disabled:opacity-70 disabled:bg-red-600" type="submit">
            {{ $t('mijn_gegevens.Verzenden') }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

  <script>

  import {
    Toast,
    Input,
    initTE,
  } from "tw-elements";
  import ErrorToast from "../ErrorToastComponent";
  import SuccessToast from "../SuccessToastComponent";
  import {SEMICOLON} from "../../semicolon_vanilla";



export default {
  mounted() {
    initTE({ Input,Toast });
    console.log('customer data form mounted.');
    let currentObj = this;
    this.getCustomerData();
    this.setEdit(false);
  },
  data() {
    return {
      initials: '',
      first_name:'',
      insertion:'',
      last_name:'',
      suffix: '',
      company_name:'',
      address: '',
      zip_code: '',
      city: '',
      mobile: '',
      edit: '',
      error_messages: [],
      success_messages: [],
      error_toast_id: 'error-toast-vue',
      success_toast_id: 'success-toast-vue'
    };
  },
  methods: {
    getCustomerData: function(){
      let currentObj = this;
      axios.post('/ajax/get_user_data')
          .then(function(response){
                currentObj.initials= response.data.klant.voorletters;
                currentObj.first_name= response.data.klant.voornaam;
                currentObj.insertion= response.data.klant.tussenvoegsel;
                currentObj.last_name= response.data.klant.naam;
                currentObj.suffix= response.data.klant.achtervoegsel;
                currentObj.company_name= response.data.klant.praktijk;
                currentObj.address= response.data.klant.adres;
                currentObj.zip_code= response.data.klant.postcode;
                currentObj.city= response.data.klant.plaats;
                currentObj.mobile= response.data.klant.mobiel;
          })
    },
    setEdit: function(mode){
        this.edit = mode;
    },
    formSubmitEditUser(e){
      e.preventDefault();
      let currentObj = this;
      let store = this.$store;
      axios.post('/ajax/set_user_data_registration', {
        voorletters: currentObj.initials,
        voornaam: currentObj.first_name,
        tussenvoegsel: currentObj.insertion,
        naam: currentObj.last_name,
        achtervoegsel: currentObj.suffix,
        praktijk: currentObj.company_name,
        adres: currentObj.address,
        postcode: currentObj.zip_code,
        plaats: currentObj.city,
        mobiel: currentObj.mobile,
      }).then(function (response) {
            if(response.data.alert==='error' || response.data.result==='fail'){
              currentObj.clearErrors();
              currentObj.addError(response.data.message);
              Toast.getOrCreateInstance(document.getElementById('error-toast-vue')).show();
              document.getElementById('error-toast-vue').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
            }else{
              currentObj.clearSuccessMessages();
              currentObj.addSuccessMessage(response.data.message);
              Toast.getOrCreateInstance(document.getElementById('success-toast-vue')).show();
              document.getElementById('success-toast-vue').scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
              currentObj.setEdit(false);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    notifyError: function(msg){
      this.addError(msg);
      SEMICOLON.widget.showErrorToast('error-toast-vue',initTE,Toast);
    },
    notifySuccess: function(msg){
      this.addError(msg);
      SEMICOLON.widget.showSuccessToast('success-toast-vue',initTE,Toast);
    },
    addError: function(e) {
      this.error_messages.push(e);
    },
    clearErrors: function(){
      this.error_messages = [];
    },
    addSuccessMessage: function(msg){
      this.success_messages.push(msg);
    },
    clearSuccessMessages: function(){
      this.success_messages = [];
    },
    clearMessages: function(){
      this.clearErrors();
      this.clearSuccessMessages();
    }

  },
  components: {
    ErrorToast,
    SuccessToast,
  },
}
</script>