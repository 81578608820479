<template>
        <component :is="componentName()" :item="item" :lang="lang"  :key="item.id"/>
</template>

<script>
	import BillItemPrijsComponent from './BillItemPrijsComponent';
    import BillItemPrijs_6Component from './BillItemPrijsComponent';
    import BillItemVoucherComponent from './BillItemVoucherComponent';

    
    export default {
    	props: ['item','lang'],
        methods: {
            componentName(){
            	return "BillItem"+this.item.soort.charAt(0).toUpperCase() + this.item.soort.slice(1)+"Component"
            }

        },
        mounted() {
            console.log('Bill item factory mounted.')
        },
        components: {
        	BillItemPrijsComponent, 
            BillItemVoucherComponent,
            BillItemPrijs_6Component
        }
    }
</script>