<template>
	<div class="grid grid-cols-12 py-1 shoppingcart_item_row" >
			<div class="col-span-10 sm:col-span-8 label_div shoppingcart_div px-3.5" v-html=titel></div>
            <div class="col-span-1 hidden sm:inline-block  shoppingcart_div">{{ aantal }}</div>
			<div class="col-span-1 hidden sm:inline-block col-prijs  shoppingcart_div">€ {{ item.value.replace(',-','') }}</div>
            <div class="col-span-1 text-right shoppingcart_div pr-1 col-euro">€</div>
			<div class="col-span-1 shoppingcart_div col-prijs text-right">
				 {{ calculatePrice()  }}
			</div>
		</div>
</template>
<script>
	import aanmeldingEntryMethods from '../../methods/AanmeldingEntryMethods';
	export default {    	
        mounted() {
            console.log('Bill Item mounted.');
        },
        props: ['item','lang'],
        computed: {
        	titel: function(){
        		return aanmeldingEntryMethods.getEntryTitel(this);
        	},
            aantal: function(){
                if(this.item.aantal>1){
                    return this.item.aantal+'x';
                }
                return '';
            },
            mathAantal: function(){
                if(this.item.aantal>1){
                    return this.item.aantal;
                }
                return 1;
            }
        },
        methods: {
        	removeItem: function(){
        		aanmeldingEntryMethods.deleteShoppingCartItem(this);
        	},
            calculatePrice: function(){
              return ((this.mathAantal*parseFloat(this.item.value.replace(',-',',00'))).toFixed(2)).replace('.',',');
            }
        }
    }
</script>