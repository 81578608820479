import { Model } from 'vue-mc'
import { required } from 'vue-mc/validation'

export default class AanmeldingEntry extends Model {

    defaults() {
        return {
            id: null,
            aanmelding_id: '',
            aanmeldformulier_id: '',
            value: '',
            voucher_reserved: '',
            klant_id: '',
            sessie_id: '',
            volgnummer: '',
            soort: '',
            aantal: '',
            voucher_valid: '',
            voucher_message: '',
            voucher_value: '',
            voucher_type: ''
        };
    }

    options() {
        return {
            useFirstErrorOnly: true,
        };
    }

    validation() {
        return ;
    }

    mutations() {
        return {
            aanmelding_id: Number,
            aanmeldformulier_id: Number,
            voucher_reserved: Boolean,
            sessie_id: Number,
        };
    }

    routes() {
        return {
            save:   '/aanmelding_entry',
            delete: '/aanmelding_entry/{id}',
            update: '/aanmelding_entry/{id}',
            fetch: '/aanmelding_entry/{id}'
        };
    }
}
