<template>
	<div >
	  {{ translation }}
	</div>
</template>

<script>

    export default {
        props:['label'],
        mounted() {
            console.log('label div mounted.')
        },
        computed: {
            translation: function(){
                return this.$i18n.t(this.label);
            }
        }
    }
</script>