<template>
	<div class="container">

    <div
        data-te-modal-init
        class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
        id="AanmeldFormulierModal"
        tabindex="-1"
        aria-labelledby="AanmeldFormulierModalCenterTitle"
        aria-modal="true"
        role="dialog">
      <div
          data-te-modal-dialog-ref
          class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[970px] min-[1200px]:max-w-[1170px]"
      >
        <div
            class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
          <div
              class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
            <ErrorToast :id="error_toast_id" :error_messages="error_messages"></ErrorToast>
            <SuccessToast :id="success_toast_id" :success_messages="success_messages"></SuccessToast>
            <!--Modal title-->

            <!--Close button-->
            <button
                type="button"
                @click="closeModal"
                class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                data-te-modal-dismiss
                aria-label="Close">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6">
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <a href="/logout">
              <button class="bg-registration-button text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" >
                Logout
              </button>
            </a>
          </div>

          <!--Modal body-->
          <div class="relative p-4">
            <ShoppingCartContainer :lang="lang" ></ShoppingCartContainer>
            <FormulierFactory  v-for="(item,index) in items"  :item="item" :lang="lang" :index="index"  :key="item.id"></FormulierFactory>
            <div class="p-4"><MandatoryCheckbox :lang="lang" identifier="bottom" ></MandatoryCheckbox></div>
          </div>

          <!--Modal footer-->
          <div
              class="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">

            <button
                type="button"
                v-on:click="volgende_aanmelding"
                class="mr-2 inline-block rounded bg-project-default px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                data-te-ripple-init
                data-te-ripple-color="light">
              {{ $t('aanmelding.Volgende aanmelding') }}
            </button>

            <button
                type="button"
                v-on:click="afronden"
                class="inline-block rounded bg-project-default px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                data-te-modal-dismiss
                data-te-ripple-init
                data-te-ripple-color="light">
              {{ $t('aanmelding.Afronden') }}
            </button>
          </div>
        </div>
      </div>
    </div>


	</div>
</template>

<script>
	  import FormulierFactory from './FormulierFactoryComponent';
    import ShoppingCartContainer from '../shoppingcart_vsc/ShoppingCartContainerComponent'
    import MandatoryCheckbox from './MandatoryCheckboxComponent';
	  import AanmeldingModalMethods from '../../methods/AanmeldingModalMethods';
    import AanmeldingButtonsMethods from '../../methods/AanmeldingButtonsMethods';
    import ErrorToast from '../ErrorToastComponent';
    import SuccessToast from '../SuccessToastComponent';
    import {
      Toast,
      Popover,
      Ripple,
      Modal,
      initTE,
    } from "tw-elements";
    import language from '../../methods/lang';
    import {SEMICOLON} from "../../semicolon_vanilla";

    export default {
        mounted() {
            console.log('RegistrationForm mounted.');
            let currentObj = this;
            let store = this.$store;
            AanmeldingModalMethods.openTweModal(currentObj,'AanmeldFormulierModal', initTE,Modal);
            SEMICOLON.widget.toggles();
            SEMICOLON.widget.extras();

        },
        data() {
            let store = this.$store;
            return {
              lang: store.getters.getLang,
              formClass: 'col-md-12 col-sm-12',
              cartClass: 'col-md-4 col-sm-12',
              currentModal: '',
              error_messages: [],
              success_messages: [],
              error_toast_id: 'error-toast-vue',
              success_toast_id: 'success-toast-vue'
            };
        },
        methods: {
        	volgende_aanmelding: function(){
        		let currentObj = this;
        		let store = this.$store;
                this.handleSelects();
                if(AanmeldingButtonsMethods.validate(this)){
                    store.dispatch('fillBill');
                    store.dispatch('addSession',{   symposium_id:store.getters.getAanmelding.aanmelding.symposium_id,
                                                    aanmelding_id:store.getters.getAanmelding.id});
            		currentObj.$parent.$router.push({ path: '/login_ajax_aanmelding', query: { symposium_id: store.getters.getAanmelding.aanmelding.symposium_id }});
                }
        	},
        	setCartBig: function(){
        		this.formClass = 'col-md-6 col-sm-12';
        		this.cartClass = 'col-md-6 col-sm-12';
        	},
        	setCartSmall: function(){
        		this.formClass = 'col-md-8 col-sm-12';
        		this.cartClass = 'col-md-4 col-sm-12';
        	},
        	afronden: function(){
        		let currentObj = this;
        		let store = this.$store;
                this.handleSelects();
                if(AanmeldingButtonsMethods.validate(this)){
                    store.dispatch('fillBill');
                    currentObj.$parent.$router.push({ path: '/registration_confirm', query: { symposium_id: store.getters.getAanmelding.aanmelding.symposium_id, lang: this.lang }});
                }
        	},
            notifyError: function(msg){
                this.addError(msg);
                SEMICOLON.widget.showErrorToast('error-toast-vue',initTE,Toast);
            },
            handleSelects: function(){
                this.$root.$emit('handle_select');
            },
            closeModal: function () {
              this.currentModal.hide();
            },
            addError: function(e) {
              this.error_messages.push(e);
            },
            clearErrors: function(){
              this.error_messages = [];
            },
            addSuccessMessage: function(msg){
              this.success_messages.push(msg);
            },
            clearSuccessMessages: function(){
              this.success_messages = [];
            },
            clearMessages: function(){
              this.clearErrors();
              this.clearSuccessMessages();
            }
        },
        components: {
            FormulierFactory,
            MandatoryCheckbox,
            ShoppingCartContainer,
            ErrorToast,
            SuccessToast,
        },
        computed: {
              items: function(){
               	 return this.$store.getters.getAanmeldOpties.models;
              },
              elementResult: function(){
                return $('#form-result-registration');
              }
        },
		created() {
		    this.$store.watch(
		      (state, getters) => getters.getAanmeldOpties.models,
		      () => {
		        //this.items = this.$store.getters.getAanmeldOpties.models;;
		      },
		    );
  		}
    }
</script>




