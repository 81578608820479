import { Collection } from 'vue-mc'
import AanmeldingSession from '../models/AanmeldingSession'
import _ from 'lodash'

export default class AanmeldingSessionCollection extends Collection {

    model() {
        return AanmeldingSession;
    }


    routes() {
        return {
            fetch: '/aanmelding_sessions/{id}',
        }
    }
}
