<template>
    <div >
    <slot></slot>
        <div
            data-te-modal-init
            class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none infoModal"
            id="exampleModalVarying"
            tabindex="-1"
            aria-labelledby="exampleModalVaryingLabel"
            aria-modal="true"
            role="dialog">
            <div
                data-te-modal-dialog-ref
                class="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px]">
                <div
                    class="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
                    <div
                        class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                        <!--Modal title-->
                        <h5
                            class="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200"
                            id="exampleModalVaryingLabel">
                            Modal title
                        </h5>
                        <!--Close button-->
                        <button
                            @click="closeModal"
                            type="button"
                            class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-te-modal-dismiss
                            aria-label="Close">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="h-6 w-6">
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>

                    <!--Modal body-->
                    <div class="relative p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3" data-te-modal-body-ref>
                        <div class="relative w-56 h-56 transform overflow-hidden rounded-full m-auto md:m-2 ">
                            <div class="absolute bottom-6 left-0 right-4 top-0 rounded-full border transition duration-300 "></div>
                            <div id="speaker_img" class="absolute inset-0 bg-white">

                            </div>
                        </div>
                        <div id="speaker_info" class="py-2 lg:col-span-2"></div>

                    </div>

                    <!--Modal footer-->

                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {
        Modal,
        initTE,
    } from "tw-elements";
    export default {
        mounted() {
            console.log('image info component mounted.');
            let currentObj = this;
            initTE({Modal});
            this.setValid();
            this.addClickHandlerImg();

        },
        props: [],
        data() {
            return {
                valid: false,
                currentModal: ''
            }
        },
        methods: {
            setValid: function(e) {
                let valid = true;
                if(!this.$el.querySelector("img")){
                    valid = false;
                }
                if(!this.$el.querySelector(".image-info-content")){
                    valid = false;
                }
                if(!this.$el.querySelector(".speaker-name")){
                    valid = false;
                }
                this.valid = valid;
            },
            addClickHandlerImg: function(e){
                if(!this.valid){
                    return;
                }
                let currentObj = this;
                let img = this.$el.querySelector("img");
                img.addEventListener('click', (event) => {
                    currentObj.showInfo();
                });
            },
            showInfo: function(){
                let info = this.$el.querySelector(".image-info-content");
                let modal = this.$el.querySelector('.infoModal');
                let speakerName = this.$el.querySelector(".speaker-name");
                let speakerImg = this.$el.querySelector("img");
                this.currentModal = new Modal(modal, {});
                let modalTitle = modal.querySelector('#exampleModalVaryingLabel');
                let modalBody = modal.querySelector('[data-te-modal-body-ref]');
                let modalSpeakerImgParent = modalBody.querySelector('#speaker_img');
                let modalSpeakerInfoParent = modalBody.querySelector('#speaker_info');

                modalTitle.textContent = speakerName.innerHTML;
                modalSpeakerImgParent.innerHTML = speakerImg.outerHTML;
                modalSpeakerInfoParent.innerHTML = info.innerHTML;
                this.currentModal.show();
            },
            closeModal: function(){
                this.currentModal.hide();
            }
        },
        components: {

        }
      }
</script>
