import { Model } from 'vue-mc'
import { required, length } from 'vue-mc/validation'
import AanmeldOptieCollection from '../collections/AanmeldOptieCollection';

export default class SubSymposium extends Model {

    defaults() {
        return {
            id: null,
            name: '',
            html: '',
            volgnummer: '',
            current: false,
            _aanmeldopties: ''
        }
    }

    get aanmeldopties() {
        return this._aanmeldopties;
    }

    options() {
        return {
            useFirstErrorOnly: true,
        }
    }

    validation() {
        return {

        }
    }

    mutations() {
        return {
        }
    }

    routes() {
        return {

        }
    }

    retrieveAanmeldopties(){
        this._aanmeldopties = new AanmeldOptieCollection();
        this._aanmeldopties.fill(this.id);
    }

}