<template>
	<div class="shoppingcart_div">
		<div class="grid">
			<SessionNaamCompleet  :session="session" :key="session.id"></SessionNaamCompleet>
		</div>
    <div v-if="items.length==0" class="grid grid-cols-12">
        <div class="col-span-12">
          {{ $t('aanmelding.Nog geen gegevens') }}
        </div>
    </div>
		<ShoppingCartItemFactory v-for="item in items"  :item="item" :lang="lang"  :key="item.id"></ShoppingCartItemFactory>
	</div>
</template>

<script>
	import ShoppingCartItemFactory from './ShoppingCartItemFactoryComponent';
  import SessionNaamCompleet from './SessionNaamCompleetComponent';
  import language from '../../methods/lang';
    export default {    	
        mounted() {
            console.log('Shopping Cart Session mounted.');
        },
        props: ['session'],
        data() {
            let store = this.$store;
            let itemsList = store.getters.getAanmeldEntries.filter({'sessie_id':this.session.id}).models;
            return {
              lang: store.getters.getLang,
              items: store.getters.getAanmeldEntries.filter({'sessie_id':this.session.id}).models
            };
        },
        components: {
        	ShoppingCartItemFactory,
          SessionNaamCompleet
        },
        created() {
            let currentObj = this;
            this.$store.watch(
              (state, getters) => getters.getAanmeldEntries.models,
              () => {
                currentObj.items = currentObj.$store.getters.getAanmeldEntries.filter({'sessie_id':currentObj.session.id}).models;
              },
            );
        }

    }
</script>	