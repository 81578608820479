<template>
	<div >
		<ShoppingCartSession v-for="session in sessions"  :session="session"  :key="session.id"></ShoppingCartSession>
	</div>
</template>

<script>
	import ShoppingCartSession from './ShoppingCartSessionComponent';
    export default {    	
        mounted() {
            console.log('Shopping Cart mounted.');
        },
        data() {
            let store = this.$store;
            return {
              sessions: store.getters.getSessions.models,
              lang: store.getters.getLang
            };
        },
        components: {
        	ShoppingCartSession
        },
        created() {
            this.$store.watch(
              (state, getters) => getters.getSessions.models,
              () => {
                this.sessions = this.$store.getters.getSessions.models;
              },
            );
        }

    }
</script>	