<template>
    <span>
        <div class="grid grid-cols-12 shoppingcart_item_row" >
            <div class="col-span-12 shoppingcart_div label_div px-3.5" v-html="titel"></div>
        </div>
    	<div class="grid grid-cols-12 shoppingcart_item_row" >
    		<div class="col-span-11 shoppingcart_div px-3.5"> {{ item.value }}</div>
    		<div class="col-span-1 shoppingcart_div">
    			<div class="remove text-right" v-on:click="removeItem">
    				<i class="mdi mdi-close-box text-project-red" ></i>
    			</div>
    		</div>
    	</div>
    </span>
</template>
<script>
	import aanmeldingEntryMethods from '../../methods/AanmeldingEntryMethods';
	export default {    	
        mounted() {
            console.log('Shopping Cart Item mounted.');
        },
        props: ['item'],
        computed: {
        	titel: function(){
        		return aanmeldingEntryMethods.getEntryTitel(this);
        	}
        },
        methods: {
        	removeItem: function(){
        		aanmeldingEntryMethods.deleteShoppingCartItem(this);
        	}
        }
    }
</script>