<template>
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-6 mt-20">
        <ErrorToast :id="error_toast_id" :error_messages="error_messages"></ErrorToast>
        <SuccessToast :id="success_toast_id" :success_messages="success_messages"></SuccessToast>
        <div>
            <!-- office Address -->
            <div class="md:flex md:flex-row lg:justify-start lg:flex-col lg:text-left md:justify-between">
                <div class="mb-6">
                    <h1 class=" text-base font-medium mb-2 dark:text-white">Proveto BV</h1>
                    <h1 class="text-gray-400 dark:text-gray-300/60 text-sm ">PO Box 199</h1>
                    <h1 class="text-gray-400 dark:text-gray-300/60 text-sm ">3800 AD Amersfoort</h1>
                    <h1 class="text-gray-400 dark:text-gray-300/60 text-sm ">The Netherlands</h1>
                    <h1 class="text-gray-400 dark:text-gray-300/60 text-sm ">tel: +31(0)613323538</h1>
                    <h1 class="text-gray-400 dark:text-gray-300/60 text-sm ">fax: +31(0)334800836</h1>
                </div>

                <div class="mb-6">
                    <h1 class="text-gray-400 dark:text-gray-300/60 text-sm ">VAT number: NL823759647B01</h1>
                    <h1 class="text-gray-400 dark:text-gray-300/60 text-sm ">KvK: 58.225.382</h1>
                    <h1 class="text-gray-400 dark:text-gray-300/60 text-sm ">BIC: ABNANL2A</h1>
                    <h1 class="text-gray-400 dark:text-gray-300/60 text-sm ">IBAN: NL94ABNA0531399400</h1>
                    <h1 class="text-gray-400 dark:text-gray-300/60 text-sm ">e-mail: contact@proveto.nl</h1>
                </div>

            </div>
        </div>

        <div class="lg:col-span-2 ajax-form">
            <!-- Contact Form -->
            <form method="post" action="/ajax/send_contact_form" name="template-contactform" id="template-contactform">
                <p id="error-msg"></p>
                <div id="simple-msg"></div>
                <div class="space-y-6">
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <!-- Name Input -->
                        <input type="text" name="template_contactform_name" id="name"
                               class="border border-gray-300 text-gray-900 text-sm rounded focus:ring-0 focus:border-gray-400 block w-full p-3 dark:bg-zinc-700/20 dark:border-zinc-700/50 dark:placeholder:text-gray-300/50 dark:text-gray-300/50"
                               placeholder="Enter your name">

                        <!-- Email ID Input -->
                        <input type="email"
                               class="border border-gray-300 text-gray-900 text-sm rounded focus:ring-0 focus:border-gray-400 block w-full p-3 dark:bg-zinc-700/20 dark:border-zinc-700/50 dark:placeholder:text-gray-300/50 dark:text-gray-300/50"
                               id="email" name="template_contactform_email" placeholder="Enter your email">
                    </div>

                    <!-- Subject Input -->
                    <input type="text"
                           class="border border-gray-300 text-gray-900 text-sm rounded focus:ring-0 focus:border-gray-400 block w-full p-3 dark:bg-zinc-700/20 dark:border-zinc-700/50 dark:placeholder:text-gray-300/50 dark:text-gray-300/50"
                           name="subject" id="subject" placeholder="Enter your subject">

                    <!-- Maessage Textarea Input -->
                    <textarea
                        class="border border-gray-300 text-gray-900 text-sm rounded focus:ring-0 focus:border-gray-400 block w-full p-3 dark:bg-zinc-700/20 dark:border-zinc-700/50 dark:placeholder:text-gray-300/50 dark:text-gray-300/50"
                        placeholder="Enter your message" name="template_contactform_message" id="comments" rows="3"></textarea>

                    <!-- Form Submit Button -->
                    <div class="text-right">
                        <input type="submit" id="submit" name="send" class="btn bg-vscblue text-white"
                               value="Send Message">
                    </div>
                </div>
            </form>
            <!--end form-->
        </div>
    </div>
</template>

<script>
    import ErrorToast from '../vendor/components/ErrorToastComponent';
    import SuccessToast from '../vendor/components/SuccessToastComponent';
    import {SEMICOLON} from "../vendor/semicolon_vanilla";
    import {
        Toast,
        Ripple,
        initTE,
    } from "tw-elements";
    export default {
        mounted() {
            console.log('contact form mounted.');
            let currentObj = this;
            initTE({Ripple});
            SEMICOLON.widget.ajaxForm(currentObj,initTE,Toast,currentObj.error_toast_id,currentObj.success_toast_id);
            SEMICOLON.widget.extras();
        },
        props: [],
        data() {
            return {
                error_messages: [],
                success_messages: [],
                error_toast_id: 'error-toast',
                success_toast_id: 'success-toast'
            }
        },
        methods: {
            addError: function(e) {
                this.error_messages.push(e);
            },
            clearErrors: function(){
                this.error_messages = [];
            },
            addSuccessMessage: function(msg){
                this.success_messages.push(msg);
            },
            clearSuccessMessages: function(){
                this.success_messages = [];
            },
            clearMessages: function(){
                this.clearErrors();
                this.clearSuccessMessages();
            }
        },
        components: {
            ErrorToast,
            SuccessToast
        }
      }
</script>
