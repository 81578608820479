<template>
	<div class="list-group-item">
	  <div class="custom-control custom-radio">
	    <input type="radio" class="required custom-control-input" name="modal-feedback-cleanliness" v-bind:id="subsymposium.id" v-bind:value="subsymposium.id">
	    <label class="custom-control-label mb-0 ml-2 nott ls0" v-bind:for="subsymposium.id" v-html="this.titel"></label>
	  </div>
	</div>
</template>

<script>
    import aanmeldingEntryMethods from "../methods/AanmeldingEntryMethods";

    export default {
    	props: ['subsymposium'],
        mounted() {
            console.log('subsymposium mounted.')
        },
        computed: {
            titel: function(){
                if(this.subsymposium.html==''||this.subsymposium.html===null){
                    return this.subsymposium.name;
                }
                return this.subsymposium.html;
            },

        }
    }
</script>
