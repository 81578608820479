<template>
	<div v-on:click="switchToSession" class="my-1 bg-zinc-400 border border-zinc-400 hover:border-project-red hover:bg-project-red rounded text-white px-3.5 cursor-pointer klantRow">{{ session.naam_compleet }}<i class="mdi mdi-square-edit-outline float-right"></i></div>
</template>
<script>
	export default {    	
        mounted() {
            console.log('Session Naam Compleet mounted.');
        },
        props: ['session'],
        methods: {
            switchToSession: function(){
              let currentObj = this;
              let store = this.$store;
              if(!currentObj.session.current){
                store.dispatch('switchSession',currentObj.session.id);
              }
              currentObj.$parent.$router.push({ path: '/registration_form', query: { lang:currentObj.lang }});
            }
        }
    }
</script>