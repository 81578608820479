import {
	Toast,
	initTE,
} from "tw-elements";
var SEMICOLON = SEMICOLON || {};

function debounce(func, wait, immediate) {
	var timeout, args, context, timestamp, result;
	return function() {
		context = this;
		args = arguments;
		timestamp = new Date();
		var later = function() {
			var last = (new Date()) - timestamp;
			if (last < wait) {
				timeout = setTimeout(later, wait - last);
			} else {
				timeout = null;
				if (!immediate) result = func.apply(context, args);
			}
		};
		var callNow = immediate && !timeout;
		if (!timeout) {
			timeout = setTimeout(later, wait);
		}
		if (callNow) result = func.apply(context, args);
		return result;
	};
}

var requesting = false;

var killRequesting = debounce(function () {
	requesting = false;
}, 100);





	// USE STRICT
	"use strict";


	SEMICOLON.widget = {

		init: function(){

			SEMICOLON.widget.toggles();
			SEMICOLON.widget.ajaxForm();
			SEMICOLON.widget.extras();

		},
		toggles: function(){
			var $toggle = $('.toggle');
			if( $toggle.length > 0 ) {
				$toggle.each( function(){
					var element = $(this),
						elementState = element.attr('data-state');

					if( elementState != 'open' ){
						element.children('.togglec').hide();
					} else {
						element.children('.togglet').addClass("toggleta");
					}

					element.children('.togglet').off( 'click' ).on( 'click', function(){
						$(this).toggleClass('toggleta').next('.togglec').slideToggle(300);
						return true;
					});
				});
			}
		},
		ajaxForm: function(obj,initTE,Toast,errorToastId,successToastId){
			var $ajaxForm = document.querySelectorAll('.ajax-form');
			if( $ajaxForm.length < 1 ){ return true; }
			$ajaxForm.forEach((element) => {
				var elementForm = element.querySelector('form');
				elementForm.addEventListener('submit', (event) => {
					event.preventDefault();
					obj.clearMessages();
					var valid = SEMICOLON.widget.validateRequired(elementForm,obj);
					if(!valid){
						return SEMICOLON.widget.showErrorToast(errorToastId,initTE,Toast);
					}
					const url = elementForm.getAttribute('action');
					const postvars = new FormData(elementForm);
					axios.post(url, postvars)
						.then(function (response) {
							if(response.data.alert=='error'){
								obj.addError(response.data.message);
								return SEMICOLON.widget.showErrorToast(errorToastId,initTE,Toast);
							}
							obj.addSuccessMessage(response.data.message);
							SEMICOLON.widget.showSuccessToast(successToastId,initTE,Toast);
							if(typeof obj.handleEventsAfterSuccess != undefined){
								return obj.handleEventsAfterSuccess();
							}
							return;
						})
						.catch(function (error) {
							console.dir(error);
						});
				});
			});
		},
		validateRequired: function(elementForm,obj){
			var valid = true;
			valid = SEMICOLON.widget.validateRequiredInputs(elementForm, valid, obj);
			valid = SEMICOLON.widget.validateRequiredRadioInputs(elementForm, valid, obj);
			return valid;
		},
		showErrorToast: function (toastId,initTE,Toast){
			SEMICOLON.widget.showToast(toastId,initTE,Toast);
		},
		showSuccessToast: function (toastId,initTE,Toast){
			SEMICOLON.widget.showToast(toastId,initTE,Toast);
			setTimeout(function(){Toast.getInstance(document.getElementById(toastId)).hide();},5000);
		},
		showToast: function (toastId,initTE,Toast){
			console.dir(toastId);
			console.dir(document.getElementById(toastId));
			initTE({Toast});
			Toast.getOrCreateInstance(document.getElementById(toastId)).show();
			document.getElementById(toastId).scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
		},
		extras: function(){
			initTE({ Toast });
		},
		notifications: function( element ){

			if( typeof toastr === 'undefined' ) {
				console.log('notifications: Toastr not Defined.');
				return true;
			}

			toastr.remove();
			var notifyElement = $(element),
				notifyPosition = notifyElement.attr('data-notify-position'),
				notifyType = notifyElement.attr('data-notify-type'),
				notifyMsg = notifyElement.attr('data-notify-msg'),
				notifyTimeout = notifyElement.attr('data-notify-timeout'),
				notifyCloseButton = notifyElement.attr('data-notify-close');

			if( !notifyPosition ) { notifyPosition = 'toast-top-right'; } else { notifyPosition = 'toast-' + notifyElement.attr('data-notify-position'); }
			if( !notifyMsg ) { notifyMsg = 'Please set a message!'; }
			if( !notifyTimeout ) { notifyTimeout = 5000; }
			if( notifyCloseButton == 'true' ) { notifyCloseButton = true; } else { notifyCloseButton = false; }

			toastr.options.positionClass = notifyPosition;
			toastr.options.timeOut = Number( notifyTimeout );
			toastr.options.closeButton = notifyCloseButton;
			toastr.options.closeHtml = '<button><i class="icon-remove"></i></button>';

			if( notifyType == 'warning' ) {
				toastr.warning(notifyMsg);
			} else if( notifyType == 'error' ) {
				toastr.error(notifyMsg);
			} else if( notifyType == 'success' ) {
				toastr.success(notifyMsg);
			} else {
				toastr.info(notifyMsg);
			}

			return false;
		},
		showRedBorder: function(element){
			element.classList.add('border');
			element.classList.add('border-red-500');
		},
		hideRedBorder: function(element){
			element.classList.remove('border');
			element.classList.remove('border-red-500');
		},
		setInputInvalid: function(input){
			input.setCustomValidity("Invalid field.");
			input.addEventListener('focus', (event) => {
				input.setCustomValidity('');
			})
		},
		hideRedBorderOnFocusRadio: function(element){
			var inputs = element.querySelectorAll('input');
			inputs.forEach( (input) => {
				input.addEventListener('change', (event) => {
					SEMICOLON.widget.hideRedBorder(element);
				})
			})
		},
		validateRequiredInputs: function(elementForm, valid, obj){
			var requiredInputs = elementForm.querySelectorAll('input[semicolon-required]');
			requiredInputs.forEach((input) => {
				if(input.value === '' && input.hasAttribute('data-msg-required')){
					obj.addError(input.getAttribute('data-msg-required'));
					SEMICOLON.widget.setInputInvalid(input);
					valid = false;
					return true;
				}
				if(input.value === '' && elementForm.querySelector("label[for='"+input.id+"']")){
					obj.addError(elementForm.querySelector("label[for='"+input.id+"']").innerHTML+' is required!');
					SEMICOLON.widget.setInputInvalid(input);
					valid = false;
					return true;
				}
			});
			return valid;
		},
		validateRequiredRadioInputs: function(elementForm, valid, obj){
			var requiredRadioInputs = elementForm.querySelectorAll('*[semicolon-radio-required]');
			requiredRadioInputs.forEach((element) => {
				var checkedInputs = element.querySelectorAll('input:checked');
				if(checkedInputs.length>0){
					return true;
				}
				obj.addError(element.getAttribute('data-msg-required'));
				SEMICOLON.widget.showRedBorder(element);
				SEMICOLON.widget.hideRedBorderOnFocusRadio(element);
				valid = false;
			});
			return valid;
		}
	};


	var $window = window;
	var $body = document.querySelector('body');








export {SEMICOLON};


	// $(document).ready( this.documentOnReady.init );
	// this.$window.on( 'load', this.documentOnLoad.init );
	// this.$window.on( 'resize', this.documentOnResize.init );
	// this.$window.this = this;


