<template>
	<div class="grid grid-cols-12 shoppingcart_item_row" >
            <div class="col-span-6 label_div shoppingcart_div px-3.5" >{{ $t('aanmelding.btw') }}</div>
			<div class="col-span-5 shoppingcart_div text-right"> {{ item.value }}</div>
			<div class="col-span-1 shoppingcart_div">
				<div class="remove text-right" v-on:click="removeItem">
					<i class="mdi mdi-close-box text-project-red" ></i>
				</div>
			</div>
		</div>
</template>
<script>
	import aanmeldingEntryMethods from '../../methods/AanmeldingEntryMethods';
    import language from '../../methods/lang';
	export default {    	
        mounted() {
            console.log('Shopping Cart Item mounted.');
        },
        props: ['item'],
        methods: {
        	removeItem: function(){
        		aanmeldingEntryMethods.deleteShoppingCartItem(this);
        	}
        }
    }
</script>