<template>
    <span>
	<div class="grid grid-cols-12 py-1 shoppingcart_item_row" >
			<div class="col-span-10 sm:col-span-9 label_div shoppingcart_div px-3.5" ><span v-html="titel"></span> {{ item.value }}</div>
			<div class="col-span-1 hidden sm:inline-block  shoppingcart_div"><span v-if="item_valid_and_not_vast">€ {{ item.voucher_value }}</span></div>
            <div class="col-span-1 text-right shoppingcart_div pr-1 col-euro"><span v-if="item_valid_and_not_vast">€</span></div>
            <div class="col-span-1 shoppingcart_div col-prijs text-right">
                 <span v-if="item_valid_and_not_vast">- {{ item.voucher_value }}</span>
            </div>
		</div>
        <div v-if="item_invalid" class="grid grid-cols-12 py-1 shoppingcart_item_row" >
            <div  class="col-span-12 label_div shoppingcart_div" ><i class="text-danger">{{ item.voucher_message }}</i>
            </div>
        </div>
    </span>
</template>
<script>
	import aanmeldingEntryMethods from '../../methods/AanmeldingEntryMethods';
	export default {
        mounted() {
            console.log('Bill Item mounted.');
        },
        props: ['item','lang'],
        computed: {
        	titel: function(){
        		return aanmeldingEntryMethods.getEntryTitel(this);
        	},
            item_invalid: function(){
                if(this.item.voucher_valid === false){
                    return true;
                }
                return false;
            },
            item_valid: function(){
                if(this.item.voucher_valid === false){
                    return false;
                }
                return true;
            },
            item_valid_and_not_vast: function(){
                if(this.item.voucher_valid === false){
                    return false;
                }
                if(this.item.voucher_type == 'vaste prijs voucher'){
                    return false;
                }
                return true;
            }
        },
        methods: {
        	removeItem: function(){
        		aanmeldingEntryMethods.deleteShoppingCartItem(this);
        	}
        }
    }
</script>
