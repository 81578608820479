import Vue from 'vue';
// const store = require("./vendor/store");
// const {i18n} = require("./vendor/plugins/i18n");

var dedicated_a = Vue.component('videoplayer', require('./components/VideoPlayerComponent.vue').default);
var dedicated_b = Vue.component('contact_form', require('./components/ContactFormComponent.vue').default);
var dedicated_c = Vue.component('image_info', require('./components/ImageInfoComponent.vue').default);

if(document.getElementById('video_carousel')){
    const videoplayer = new Vue({
        el: '#video_carousel',
        components: {
            'videoplayer': dedicated_a
        }
    })
}

if(document.getElementById('contact_form')){
    const contact_form = new Vue({
        el: '#contact_form',
        components: {
            'contact_form': dedicated_b
        }
    })
}

if(document.getElementById('speaker_section')){
    const contact_form = new Vue({
        el: '#speaker_section',
        components: {
            'image_info': dedicated_c
        }
    })
}
