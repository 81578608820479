<template>
	        <component :is="componentName()" :item="item"  :key="item.id"/>
</template>

<script>
    import ShoppingCartItemPrijsComponent from './ShoppingCartItemPrijsComponent';
    import ShoppingCartItemPrijs_6Component from './ShoppingCartItemPrijsComponent';
    import ShoppingCartItemTextComponent from './ShoppingCartItemTextComponent';
    import ShoppingCartItemTextareaComponent from './ShoppingCartItemTextComponent';
    import ShoppingCartItemSelectComponent from './ShoppingCartItemSelectComponent';
    import ShoppingCartItemVatComponent from './ShoppingCartItemVatComponent';
    import ShoppingCartItemVoucherComponent from './ShoppingCartItemVoucherComponent';
    import ShoppingCartItemCheckboxComponent from './ShoppingCartItemCheckboxComponent';
    import ShoppingCartItemKostenplaatsnummerComponent from "./ShoppingCartItemKostenplaatsnummerComponent";

    export default {
    	props: ['item'],
        methods: {
            componentName(){
            	return "ShoppingCartItem"+this.item.soort.charAt(0).toUpperCase() + this.item.soort.slice(1)+"Component"
            }

        },
        mounted() {
            console.log('shoppingcart vsc item factory mounted.');
        },
        components: {
        	  ShoppingCartItemPrijsComponent,
            ShoppingCartItemTextComponent,
            ShoppingCartItemSelectComponent,
            ShoppingCartItemVatComponent,
            ShoppingCartItemVoucherComponent,
            ShoppingCartItemPrijs_6Component,
            ShoppingCartItemTextareaComponent,
            ShoppingCartItemCheckboxComponent,
            ShoppingCartItemKostenplaatsnummerComponent,
        }
    }
</script>
