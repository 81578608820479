<template>
    <div>
        <div v-if="is_creditcard" class="grid grid-cols-12 py-1 shoppingcart_item_row" >
            <div class="col-span-10 label_div shoppingcart_div px-3.5" >{{ $t('aanmelding.Kosten Creditcard') }}</div>
            <div class="col-span-1 shoppingcart_div text-right pr-1 col-euro">€</div>
            <div class="col-span-1 shoppingcart_div col-prijs text-right"> {{ bill.creditcard_kosten }}
            </div>
        </div>
        <div class="grid grid-cols-12 py-1 shoppingcart_item_row" >
            <div class="col-span-12 label_div shoppingcart_div" ><hr/></div>
        </div>
    	<div  class="grid grid-cols-12 py-1 shoppingcart_item_row" >
    		<div class="col-span-10 label_div shoppingcart_div px-3.5" >{{ $t('aanmelding.Totaal ex BTW') }}</div>
            <div class="col-span-1 shoppingcart_div text-right pr-1 col-euro">€</div>
    		<div  class="col-span-1 shoppingcart_div col-prijs text-right"> {{ bill.ex_btw }}
    		</div>
    	</div>
        <div  class="grid grid-cols-12 py-1 shoppingcart_item_row" >
            <div class="col-span-10 label_div shoppingcart_div px-3.5" >{{ $t('aanmelding.21% BTW') }}</div>
            <div class="col-span-1 shoppingcart_div text-right pr-1 col-euro">€</div>
            <div class="col-span-1 shoppingcart_div col-prijs text-right"> {{ bill.btw_hoog }}
            </div>
            
        </div>
        <div v-if="btw_laag_is_not_nul" class="grid grid-cols-12 py-1 shoppingcart_item_row" >
            <div class="col-span-10 label_div shoppingcart_div px-3.5" >{{ $t('aanmelding.9% BTW') }}</div>
            <div class="col-span-1 shoppingcart_div text-right pr-1 col-euro">€</div>
            <div class="col-span-1 shoppingcart_div col-prijs text-right"> {{ bill.btw_laag }}
            </div>
        </div>
        <div class="grid grid-cols-12 py-1 shoppingcart_item_row" >
            <div class="col-span-12 label_div shoppingcart_div" ><hr/></div>
        </div>
        <div  class="grid grid-cols-12 py-1 shoppingcart_item_row" >
            <div class="col-span-10 label_div shoppingcart_div px-3.5" >{{ $t('aanmelding.Totaal') }}</div>
            <div class="col-span-1 shoppingcart_div text-right pr-1 col-euro">€</div>
            <div class="col-span-1 shoppingcart_div col-prijs text-right"> {{ bill.totaal }}
            </div>
        </div>
    </div>
</template>
<script>
	import aanmeldingEntryMethods from '../../methods/AanmeldingEntryMethods';
	export default {    	
        mounted() {
            console.log('Bill Total mounted.');
            this.setBill();
        },
        props: ['lang'],
        data() {
            return {
                bill : {},
                betaling: 'factuur'
            }
        },
        computed: {
            btw_laag_is_not_nul: function(){
                if(this.bill.btw_laag=='0,00'){
                    return false;
                }
                return true;
            },
            is_creditcard: function(){
                if(this.bill.creditcard_kosten=='0,00'){
                    return false;
                }
                return true;
            }
        },
        methods: {
        	setBill: function(){
                let store = this.$store;
                this.bill = store.getters.getBill;
            },
            setBetaling: function(){
                let store = this.$store;
                this.betaling = store.getters.getBetaling;
            }
        },
        created() {
            this.$store.watch(
              (state, getters) => getters.getBill,
              () => {
                this.setBill();
              },
            );
            this.$store.watch(
              (state, getters) => getters.getBetaling,
              () => {
                this.setBetaling();
              },
            );
        }
    }
</script>