<template>
        <a @click="gotoLogin" class="pull-right buy-btn bg-transparent border border-uveblue text-uveblue font-bold py-2 px-6 rounded-md transition duration-300 ease-in-out hover:bg-project-red hover:text-white">{{ $t('event.Inschrijven') }} </a>
</template>

<script>
    import AanmeldingModalMethods from '../methods/AanmeldingModalMethods';
    export default {
        mounted() {
            console.log('AanmeldButton mounted.');
            let store = this.$store;
        },
        props:['symposium_id'],
        methods: {
            gotoLogin: function () {
                let currentObj = this;
                AanmeldingModalMethods.gotoLogin(currentObj);
            }
        },
        created() {
            let currentObj = this;
            let store = this.$store;
            store.watch(
              (state, getters) => getters.getAanmelding.aanmelding.definitief,
              () => {
                if(store.getters.getPreAanmeldingTriggered){
                    return;
                }
                if(store.getters.getAanmelding.aanmelding.definitief){
                    store.dispatch('setPreAanmeldingTriggered');
                    store.dispatch('flushSessions');
                    store.dispatch('preAanmelding',{symposium_id:currentObj.symposium_id});
                }
              },
            );
        }
    }
</script>
