
<template>
    <div class="container">

        <!-- Modal -->
        <div class="modal1 mfp-hide fadeIn fast" id="addEmailUserModal" >

            <div class="card noborder divcenter" style="max-width: 500px;" >
                <img :src="image.url" class="card-img-top" alt="Image">
                <div class="page-transition-wrap page-transition-wrap-modal d-none">
                    <div class="css3-spinner css3-spinner-modal">
                        <div class="css3-spinner-bounce1"></div>
                        <div class="css3-spinner-bounce2"></div>
                        <div class="css3-spinner-bounce3"></div>
                    </div>
                </div>
                <a href="#" class="h4 text-muted-50" onClick="$.magnificPopup.close();return false;"><i class="icon-line-circle-cross" style="position:absolute;right:5px"></i></a>
                <div class="card-body p-4">
                    <h3 class="card-title font-body">{{ $t('registratie.Email toevoegen') }}</h3>
                    <div >
                        <form @submit="formSubmit">
                            <div class="form-result"></div>
                            <div class="form-process"></div>
                            <div id="addEmailUserModalMsg" class="alert alert-danger hidden">
                                <p></p>
                            </div>
                            <div class="row">
                                <div class="col-12 form-group">
                                    <div class="form-group ">
                                        <label  class="control-label">{{ $t('registratie.Name') }}</label>
                                        <div>
                                            {{ user_name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 form-group">
                                    <div class="form-group ">
                                        <label for="email" class="control-label">{{ $t('registratie.Email') }}</label>
                                        <div>
                                            <input :placeholder="$t('registratie.add_email_placeholder')" class="form-control form-control required" id="email" name="email" type="email" v-model="email">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <button class ="btn btn-success btn-block py-2">{{ $t('registratie.verstuur') }}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import AanmeldingModalMethods from '../methods/AanmeldingModalMethods';
export default {
    mounted() {
        console.log('AddEmailUserModal mounted.');
        let currentObj = this;
        $(document).ready(function(){
            currentObj.validateToken();
        });
    },
    props:['email_token'],
    data() {
        return {
            email: '',
            user_name: '',
            image: { url: '/images/aanmelding/deelnemer.jpg' }
        };
    },
    methods: {
        formSubmit: function(e) {
            e.preventDefault();
            let currentObj = this;
            let store = this.$store;
            currentObj.loader();
            axios.post('/add_email_by_token', {
                email: currentObj.email,
                email_token: currentObj.email_token
            })
            .then(function (response) {
                currentObj.hideloader();
                $('#addEmailUserModalMsg').find('p').html(response.data.message);
                $('#addEmailUserModalMsg').removeClass('hidden');
                $('#addEmailUserModalMsg').removeClass('alert-danger');
                $('#addEmailUserModalMsg').addClass('alert-success');
                $('#addEmailUserModal').find('input#email').val('');
            })
            .catch(function (error) {
                currentObj.hideloader();
                if (error.response) {
                    if(error.response.data.email){
                        $('#addEmailUserModalMsg').find('p').html(error.response.data.email[0]);
                    }
                    if(error.response.data.email_token){
                        $('#addEmailUserModalMsg').find('p').html(error.response.data.email_token[0]);
                    }

                } else {
                    $('#addEmailUserModalMsg').find('p').html(error);
                }
                $('#addEmailUserModalMsg').removeClass('hidden');
            });
        },
        validateToken: function(){
            let currentObj = this;
            axios.get('/get_data_by_token/'+currentObj.email_token)
            .then(function (response) {
                currentObj.hideloader()
                currentObj.user_name = response.data.user_name;
                AanmeldingModalMethods.openModalGeneric(currentObj,'#addEmailUserModal');
            })
            .catch(function (error) {
                currentObj.hideloader()
                AanmeldingModalMethods.openModalGeneric(currentObj,'#addEmailUserModal');
                if (error.response) {
                    $('#addEmailUserModalMsg').find('p').html(error.response.data.token[0]);
                } else {
                    $('#addEmailUserModalMsg').find('p').html(error);
                }
                $('#addEmailUserModalMsg').removeClass('hidden');
            });
        },
        loader: function(){
            $('.page-transition-wrap-modal').removeClass('d-none');
        },
        hideloader: function(){
            $('.page-transition-wrap-modal').addClass('d-none');
        }
    }
}
</script>
