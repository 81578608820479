import Aanmelding from './models/Aanmelding';
import AanmeldOptie from './models/AanmeldOptie';
import AanmeldingSession from './models/AanmeldingSession';
import AanmeldingSessionCollection from './collections/AanmeldingSessionCollection';
import SessionPropertyBagCollection from './collections/SessionPropertyBagCollection';
import AanmeldOptieCollection from './collections/AanmeldOptieCollection';
import Bill from './models/Bill';
import AanmeldingModalMethods from './methods/AanmeldingModalMethods';


export default class AanmeldingObject{

    constructor(){
        this.initBlank();
    }

    get aanmelding(){
        return this._aanmelding;
    }

    get sessions(){
        return this._sessions;
    }

    get sessionPropertyBag(){
        return this._sessionPropertyBag;
    }

    get current_session(){
        return this._current_session;
    }

    get aanmeldOpties(){
        return this._aanmeldOpties;
    }

    get getCurrentSessieId(){
        if(this._current_session == ''){
            return '';
        }
        return this._current_session.id;
    }

    get bill(){
        return this._bill;
    }

    get symposiumId(){
        return this._aanmelding.symposium_id;
    }

    get mandatoryCheckboxIsChecked(){
        return this._aanmelding.privacy;
    }

    createAanmelding(payload, store){
        this._aanmelding = new Aanmelding(payload);
    }

    saveAanmelding(store){
        this._aanmelding.modify().then(
                () => {
                store.dispatch('fillBill');
            }).catch((error) => {
                // Handle failure here
            });
    }

    finishAanmelding(store){
        let currentObj = this;
        axios.post('/aanmelding_finish', {
            aanmelding_id: currentObj.aanmelding.id
        })
        .then(function(){
            store.dispatch('setAanmeldingDefinitief');
        });
    }

    mailBeforePayment(){
        let currentObj = this;
        axios.post('/mail_before_payment', {
            aanmelding_id: currentObj.aanmelding.id
        });
    }

    setPrivacy(privacy){
        this._aanmelding.privacy = privacy;
    }

    setAanmeldopties(sub_symposia){
        let session = this._sessions.find({current:true});
        let sub_symposium = undefined;
        let symposium_id = this._aanmelding.symposium_id;
        if(session != undefined){
            symposium_id = session.symposium_id;
        }
        sub_symposium = sub_symposia.find({id:parseInt(symposium_id)});
        if(sub_symposium == undefined){
            return;
        }
        if(sub_symposium._aanmeldopties == ""){
            return;
        }
        this._aanmeldOpties = sub_symposium._aanmeldopties;
    }

    setAanmeldoptiesServerData(aanmeld_opties){
        this._aanmeldOpties = new AanmeldOptieCollection(aanmeld_opties);
    }

    addSession(payload,store){
        this._current_session = new AanmeldingSession(payload,this._sessions);
        this.flushCurrentProp();
        this._current_session.current = true;
        this._sessions.add(this._current_session);
        this._current_session.save();
        store.dispatch('setAanmeldopties');
    }

    setSession(payload,store){
        let findSession = this._sessions.find({id:parseInt(payload.id)});
        if(findSession != undefined){
            this.sessions.remove(findSession);
        }
        this._current_session = new AanmeldingSession(payload,this._sessions);
        this.flushCurrentProp();
        this._current_session.current = true;
        this._sessions.add(this._current_session);
        this._current_session.modify();
        store.dispatch('setAanmeldopties');
    }

    addSessionAfterRefresh(payload){
        let session = new AanmeldingSession(payload,this._sessions);
        this._sessions.add(session);
        if(session.current){
            this._current_session = new AanmeldingSession(payload,this._sessions);
            this._current_session.current = true;
        }
    }

    set_current_session_by_id(session_id,store){
        let findSession = this._sessions.find({id:parseInt(session_id)});
        if(findSession == undefined){
            return;
        }
        this.flushCurrentProp();
        findSession.current = true;
        this.update_current_session(findSession,store);
    }

    update_current_session(payload,store){
        if(this._current_session==''||typeof this._current_session=='undefined'|| typeof this._current_session.id=='undefined'){
            this.setSession(payload,store);
            return;
        }
        if(typeof payload.$class !='undefined'){
            this._current_session = payload;
            payload.modify();
        }else{
            this._current_session.set(payload);
            let findSession = this._sessions.find({id:parseInt(this._current_session.id)});
            if(typeof findSession != 'undefined'){
                findSession.set(payload);
            }
            this._current_session.modify();
        }
        store.dispatch('setAanmeldopties');
    }

    remove_session_by_id(sessie_id,store,override){
        if(this._sessions.length==1){
            console.log('there must be at least one');
            return;
        }
        if(this._current_session.id==sessie_id&&!override){
            console.log('cannot remove current session');
            return;
        }
        if(this._current_session.id==sessie_id&&override){
            let currentObj = this;
            this._sessions.each(function(item){
                if(item.id!=sessie_id&&!!item.id){
                    currentObj.update_current_session(item,store);
                    return false;
                }
            });
        }
        this._sessionPropertyBag.each(function(aanmeldingEntry){
            if(aanmeldingEntry.sessie_id==sessie_id){
                store.dispatch('deleteAanmeldingEntry',aanmeldingEntry);
            }
        });
        let findSession = this._sessions.find({id:parseInt(sessie_id)});
        if(findSession == undefined){
            return;
        }
        store.dispatch('deleteSession',findSession);
    }

    flushCurrentProp(){
        this._sessions.each((session, index) => {
            session.current = false;
        });
    }

    flushSessions(){
        this._sessions = new AanmeldingSessionCollection;
        this._sessionPropertyBag = new SessionPropertyBagCollection;
        this._current_session = '';
    }

    reInit(store,payload){
        let currentObj = this;
        axios.post('/aanmelding_homepage', {
            symposium_id: payload.symposium_id
        })
        .then(function(response){
            if(payload.router.currentRoute.path != '/'){
                payload.router.push({ path: '/'});
            }
            store.dispatch('renewSubSymposia',payload.symposium_id);
            currentObj.initBlank();
            if(response.data.user_id!=''){
                currentObj.initWithData(store,response.data,payload.router);
                return AanmeldingModalMethods.gotoTheNextPage(store,payload.router,response.data);
            }
            AanmeldingModalMethods.gotoLoginAfterFlush(payload.router,payload.symposium_id);
        })
    }

    initBlank(){
        this._aanmelding = new Aanmelding;
        this._sessions = new AanmeldingSessionCollection;
        this._sessionPropertyBag = new SessionPropertyBagCollection;
        this._aanmeldOpties = new AanmeldOptieCollection;
        this._current_session = '';
        this._bill = new Bill;
    }

    initWithData(store, data){
        if(!data.canRegister){
            return;
        }
        store.dispatch('createAanmelding',data.aanmelding_object);
        store.dispatch('setKlantId',data.user_id);
        data.aanmeld_sessions.forEach(function(aanmelding_session){
            store.dispatch('addSessionAfterRefresh',aanmelding_session);
        })
        store.dispatch('setAanmeldoptiesServerData',data.aanmeld_opties);
    }


    afterRefresh(symposium_id, store){
        axios.post('/is_logged_in', {
                    symposium_id: symposium_id
                })
                .then(function (response) {
                    if(response.data.login=='fail'){
                        return;
                    }else if(!response.data.canRegister){
                        return;
                    }else{
                        store.dispatch('createAanmelding',response.data.aanmelding_object);
                        response.data.participants.forEach((participant) => {
                                store.dispatch('addParticipant',participant);
                        });
                        store.dispatch('setKlantId',{klant_id:response.data.aanmelding_object.klant_id});
                        store.dispatch('flushSessions');
                        store.dispatch('setSessionPropertyBag',response.data.aanmeld_entries);
                        response.data.aanmeld_sessions.forEach((aanmeld_session) => {
                                store.dispatch('addSessionAfterRefresh',aanmeld_session);
                        });
                        store.dispatch('setAanmeldoptiesServerData',response.data.aanmeld_opties);
                        store.dispatch('fillBill');
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
    }
}
