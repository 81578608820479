<template>
  <div
      class="absolute top-2.5 right-2.5 z-50 pointer-events-auto mx-auto mb-4 hidden w-96 max-w-full rounded-lg bg-danger-100 bg-clip-padding text-sm text-danger-700 shadow-lg shadow-black/5 data-[te-toast-show]:block data-[te-toast-hide]:hidden"
      :id="id"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      data-te-autohide="false"
      position="top-right"
      data-te-toast-init
      data-te-toast-hide>
    <div
        class="flex items-end justify-end rounded-t-lg border-b-2 border-danger-200 bg-danger-100 bg-clip-padding px-4 pb-2 pt-2.5 text-danger-700">
        <button
            type="button"
            class="ml-2 box-content rounded-none border-none opacity-80 hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
            data-te-toast-dismiss
            aria-label="Close">
        <span
            class="w-[1em] focus:opacity-100 disabled:pointer-events-none disabled:select-none disabled:opacity-25 [&.disabled]:pointer-events-none [&.disabled]:select-none [&.disabled]:opacity-25">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-6 w-6">
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12" />
          </svg>
        </span>
        </button>
    </div>
    <div
        class="break-words rounded-b-lg bg-danger-100 px-4 py-4 text-danger-700">
      <ul>
        <li v-for="error_message in error_messages">{{ error_message }}</li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  mounted() {
    console.log('Error toast mounted.');

  },
  props: ['error_messages','id'],
}
</script>