
export default {
    setI18nLanguage: function(lang,i18n,store) {
      i18n.locale = lang;
      axios.defaults.headers.common['Accept-Language'] = lang;
      document.querySelector('html').setAttribute('lang', lang);
      store.dispatch('setLocale',lang);
      axios.post('/lang',{language:lang}).then(this.handleContent(lang,i18n));
      return lang
    },
    switchLang: function(lang,store,i18n) {
      // If the same language
      if (i18n.locale === lang) {
        return Promise.resolve(this.setI18nLanguage(lang,i18n,store))
      }

      // If the language was already loaded
      if (store.getters.getLoadedLanguages.includes(lang)) {
        return Promise.resolve(this.setI18nLanguage(lang,i18n,store))
      }

      // If the language hasn't been loaded yet
      return axios.get('/lang/'+lang).then(
        messages => {
          i18n.setLocaleMessage(lang, messages)
          store.dispatch('addLang',lang);
          return this.setI18nLanguage(lang,i18n,store);
        }
      )
    },
    handleContent: function(lang,i18n){
        $('.translatable').each(function(){
            let obj = this;
            switch(this.id){
                case "homecontent-translatable":
                    axios.post('/homecontent',{language:lang}).then(function(response){
                        $(obj).html(response.data)});
                break;
                case "section-mijn-gegevens-home":
                    axios.post('/mijn_gegevens_home_ajax',{language:lang}).then(function(response){
                        $(obj).replaceWith(response.data)
                        $('[data-toggle="popover"]').popover();
                        if($('#praktijk-account-nee').is('checked')){
                              $('.zakelijk').hide();
                              $('.particulier').show();
                        }else{
                              $('.zakelijk').show();
                              $('.particulier').hide();
                        }
                    });
                break;
                case "certificatenHtml":
                    axios.post('/mijn_certificaten_html',{language:lang}).then(function(response){
                        $(obj).html(response.data)});
                break;
                case "facturenHtml":
                    axios.post('/mijn_facturen_html',{language:lang}).then(function(response){
                        $(obj).html(response.data)});
                break;
                case "handoutsHtml":
                    axios.post('/mijn_handouts_html',{language:lang}).then(function(response){
                        $(obj).html(response.data)});
                break;
                case "mijnSymposiaHtml":
                    axios.post('/mijn_symposia_html',{language:lang}).then(function(response){
                        $(obj).html(response.data)});
                break;
                case "videosHtml":
                    axios.post('/mijn_videos_html',{language:lang}).then(function(response){
                        $(obj).html(response.data)});
                break;
                case "slider":
                    axios.post('/header_view',{language:lang}).then(function(response){
                        $(obj).replaceWith(response.data);
                    });
                break;
                case "section-quotes":
                    axios.post('/section_quotes_view',{language:lang}).then(function(response){
                        $(obj).replaceWith(response.data);
                        SEMICOLON.widget.loadFlexSlider();
                    });
                break;
                case "footer_privacy":
                  if(lang=='en'){
                    $(obj).attr('href','/proveto_privacy_en.pdf');
                  }else{
                    $(obj).attr('href','/proveto_privacy_nl.pdf')
                  }
                break;
                default:
                break
            }
        });
        $('.translatable-section').each(function(){
            let obj = this;
            let symposium_id = $(obj).attr('symposium_id');
            axios.post('/conference_section',{language:lang,section_id:obj.id,symposium_id:symposium_id}).then(function(response){
                        $(obj).html(response.data)});
        });
        $('.conference_menu').each(function(){
            let obj = this;
            let symposium_id = $(obj).attr('symposium_id');
            axios.post('/conference_menu',{language:lang,symposium_id:symposium_id}).then(function(response){
                        $(obj).html(response.data)});
        });
        $('input[placeholder-label]').each(function(){
            let obj = this;
            $(obj).attr('placeholder',i18n.t($(obj).attr('placeholder-label')));
        });
        $('.trans-label').each(function(){
            let obj = this;
            $(obj).html(i18n.t($(obj).attr('label')));
        });
        $('.bootform_translatable').each(function(){
            let obj = this;
            $(obj).parent().parent().find('label').html(i18n.t($(obj).attr('label')));
        });
        $('.bootform_translatable_submit').each(function(){
            let obj = this;
            $(obj).attr('value',i18n.t($(obj).attr('label')));
        });

    }
}