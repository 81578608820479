<template>
	    <div class="form-group row toelichting1" v-bind:for="item.id">
	    	<div :class="className" v-html="naamTranslated">
	    	</div>
	    </div>
</template>

<script>
	import language from '../../methods/lang';
    export default {
    	props: ['item','index'],
        mounted() {
            console.log('toelichting 1 mounted.')
        },
        computed: {
		    naamTranslated: language.naamTranslated,
            className: function(){
                return 'col-sm-12';
            }
        }
    }
</script>
