import { Model } from 'vue-mc'
import { required } from 'vue-mc/validation'

export default class Bill extends Model {

    defaults() {
        return {
            id: '',
            totaal: '',
            ex_btw: '',
            btw_hoog: '',
            btw_laag: '',
            creditcard_kosten: ''
        }
    }

    options() {
        return {
            useFirstErrorOnly: true,
            methods: {
                fetch: 'GET'
            }
        }
    }

    validation() {
        return ;
    }

    mutations() {
        
    }

    routes() {
        return {
            fetch: '/fetch_bill_proveto/{id}'
        }
    }
}
