<template>
	    <div class="form-group row" v-bind:for="item.id">
	    	<div class="col-sm-6" v-html="naamTranslated">
	    	</div>
	    	<div class="col-sm-6">
	    		<div>
					<el-input-number :name="item.id.toString()" v-model="aantal"  :min="1" :max="10"></el-input-number>
	    		</div>
	    		<div v-html="toelichtingTranslated">
	    		</div>
	    	</div>
	    </div>
</template>

<script>
	import language from '../../methods/lang';
	import _ from 'lodash';
    export default {
    	props: ['item','lang'],
        mounted() {
            console.log('textareacomponent mounted.');
        },
        computed: {
		    naamTranslated: language.naamTranslated,
		    toelichtingTranslated: language.toelichtingTranslated
        },
        methods: {
        	
        },
        data() {
	      return {
	        aantal: ''
	      }
	    }
    }
</script>