<template>
	<div class="p-4">
	  <div class="custom-control custom-radio">
	    <component :is="componentName()" :item="item" :index="index"  />
	  </div>
	</div>
</template>

<script>
	import PrijsComponent from './PrijsComponent';
    import Prijs_6Component from './PrijsComponent';
	import Toelichting1Component from './Toelichting1Component';
    import TextComponent from './TextComponent';
    import AantalComponent  from './AantalComponent';
    import TextareaComponent  from './TextareaComponent';
    import Toelichting2Component  from './Toelichting1Component';
    import CheckboxComponent  from './CheckboxComponent';
    import VoucherComponent  from './VoucherComponent';
    import SelectComponent  from './SelectComponent';
    import VatComponent  from './VatComponent';
    import KostenplaatsnummerComponent from "./KostenplaatsnummerComponent";

    export default {
    	props: ['item','lang','index'],
        methods: {
            componentName(){
            	return this.item.soort.charAt(0).toUpperCase() + this.item.soort.slice(1)+"Component"
            }

        },
        mounted() {
            console.log('formulier factory mounted.')
        },
        components: {
        	PrijsComponent,
            Prijs_6Component,
            Toelichting1Component,
            TextComponent,
            AantalComponent,
            TextareaComponent,
            Toelichting2Component,
            CheckboxComponent,
            VoucherComponent,
            SelectComponent,
            VatComponent,
            KostenplaatsnummerComponent,
        }
    }
</script>
