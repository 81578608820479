import { Collection } from 'vue-mc'
import Participant from '../models/Participant'
import _ from 'lodash'

export default class ParticipantCollection extends Collection {

    model() {
        return Participant;
    }

    /**
     * Returns the number of completed participants (saved state).
     */
    get completed() {
        return this.sum((participant) => participant.$.done);
    }

    /**
     * Returns the percentage of completed participants.
     */
    get progress() {
        if (this.isEmpty()) {
            return 0;
        }

        return _.round(this.completed / this.length * 100);
    }

    add(payload){
        if(this.find({id:payload.id})!=undefined){
            return;
        }
        return super.add(payload);
    }

    routes() {
        return {
            fetch: '/participant',
        }
    }
}
