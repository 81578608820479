import { Collection } from 'vue-mc'
import SubSymposium from '../models/SubSymposium'
import _ from 'lodash'

export default class SubSymposiumCollection extends Collection {

    model() {
        return SubSymposium;
    }

    /**
     * Returns the number of completed symposiums (saved state).
     */
    get completed() {
        return this.sum((symposium) => symposium.$.done);
    }

    /**
     * Returns the percentage of completed symposiums.
     */
    get progress() {
        if (this.isEmpty()) {
            return 0;
        }

        return _.round(this.completed / this.length * 100);
    }

    routes() {
        return {
            fetch: '/sub_symposia/{aanmelding_id}',
        }
    }

    fetchAanmeldOpties(){
        _.forEach(this.models, function(model) {
          model.retrieveAanmeldopties();
        });
    }

    onFetchSuccess(response){
        super.onFetchSuccess(response);
        this.fetchAanmeldOpties();
    }
}
