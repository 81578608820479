

export default{
	gotoLogin: function (obj) {
        let currentObj = this;
        let store = obj.$store;
        let path = obj.$parent.$router.currentRoute.path;
        switch(path) {
          case '/login_ajax_aanmelding':
                currentObj.openLoginModal(obj);
            break;
          case '/choose_participant':
            if(store.getters.getKlantId==''||store.getters.getParticipants==[]){
                currentObj.openLoginModal(obj);
                break;
            }
            currentObj.openModal(obj,'#AanmeldingParticipantsModal');
            break;
          case '/choose_event':
            if(store.getters.getKlantId==''||store.getters.getSubSymposia==[]){
                currentObj.openLoginModal(obj);
                break;
            }
            currentObj.openModal(obj,'#ChooseEventModal');
            break;
          default:
            obj.$parent.$router.push({ path: '/login_ajax_aanmelding', query: { symposium_id: obj.symposium_id }});
        }

    },
    gotoLoginAfterFlush(router,symposium_id){
        router.push({ path: '/login_ajax_aanmelding', query: { symposium_id: symposium_id }});
    },
    openLoginModal: function(obj){
        this.openModal(obj,'#loginModalAjax');
        obj.$root.$emit('rerenderLoginModal');
    },
    openModal: function(obj,src) {
        this.openModalGeneric(obj,src);
        switch(src){
            case '#loginModalAjax':
               obj.$parent.$router.push({ path: 'login_ajax_aanmelding', query: { symposium_id: obj.symposium_id }});
            break;
            case '#AanmeldingParticipantsModal':
               obj.$parent.$router.push({ path: 'choose_participant', query: { symposium_id: obj.symposium_id }});
            break;
            case '#ChooseEventModal':
               obj.$parent.$router.push({ path: 'choose_event', query: { symposium_id: obj.symposium_id }});
            break;
            case '#videoModal':
                obj.$parent.$router.push({ name: 'video_modal', params: { klant_video_id: obj.klant_video_id,token: obj.token,vimeo_video_id:obj.vimeo_video_id,video_url:obj.video_url}});
            break;
            case '#orderVideoModal':
                obj.$parent.$router.push({ name: 'proveto_at_home_modal_info', params: { video_id: obj.video_id, screenshot: obj.screenshot }});
            break;
            case '#orderVideoLoginModal':
                obj.$parent.$router.push({ name: 'proveto_at_home_modal_login', params: { video_id: obj.video_id }});
            break;
            case '#orderVideoBillModal':
                obj.$parent.$router.push({ name: 'proveto_at_home_modal_bill', params: { video_id: obj.video_id }});
            break;
        }

    },
    openModalGeneric: function(obj,src){
      if($(src)){
            $.magnificPopup.open({
              items: {
                src: src
              },
              closeOnBgClick: true,
              type: 'inline',
              callbacks: {
                close: function(){
                  obj.$parent.$router.push({ path: '/'});
                }
              }
            });
        }
    },
    gotoTheNextPage: function(store,router,data){
      let lang = store.getters.getLang;
      if(!data.canRegister){
        router.push({ name: 'message', params :{ message: 'aanmelding.Aanmeld_fout_verlopen'}});
        return;
      }
      switch(data.next) {
        case 'stap2_kies1':
          $(data.participants).each(function(){
              store.dispatch('addParticipant',this);
          });
          router.push({ path: 'choose_participant'});
          break;
        case 'stap2_kies2':
          router.push({ path: 'choose_event'});
          break;
        default:
          router.push({ path: 'registration_form', query: { lang:lang }});
      }
    },
    openNewWindow: function(params,url){
      let form = document.createElement("form");
      let currentObj = this;
      form.setAttribute("method", "post");
      form.setAttribute("action", url);
      form.setAttribute("target", "_self");
      params.forEach(function(param){
        currentObj.createHiddenField(param.name,param.value,form);
      });
      let token = window.axios.defaults.headers.common['X-CSRF-TOKEN'];
      currentObj.createHiddenField('_token',token,form);
      document.body.appendChild(form);
      window.open('', '_self');
      form.submit();
    },
    createHiddenField: function(name,value,form){
      let hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", name);
      hiddenField.setAttribute("value", value);
      form.appendChild(hiddenField);
    },
    openTweModal: function(obj,elementId,initTE,Modal){
        initTE({Modal});
        obj.currentModal = new Modal(document.getElementById(elementId), {});
        document.getElementById(elementId).addEventListener("hide.te.modal", (e) => {
            if (obj.$router.currentRoute.path != '/') {
                obj.$router.push({path: '/'});
            }
            obj.currentModal.hide();
        });
        const elements = document.querySelectorAll('div[data-te-backdrop-show]');
        for (const element of elements) {
            element.parentNode.removeChild(element);
        }
        obj.currentModal.show();
    }
}
