<template>
    <div class="form-group row" v-bind:for="item.id">
        <div class="col-sm-4">
            <span v-html="naamTranslated"></span>
            <Toelichting :item="item"></Toelichting>
        </div>
        <div class="col-sm-8">
            <div>
                <el-input
                    :name="item.id.toString()"
                    :placeholder="item.default_value"
                    class="vat_textbox"
                    v-model="kostenplaatsnummer"
                    v-on:keyup.native="update_value"
                    v-on:focus="set_default_value"
                >
                </el-input>
            </div>

        </div>
    </div>
</template>

<script>
import language from '../../methods/lang';
import _ from 'lodash';
import aanmeldingEntryMethods from '../../methods/AanmeldingEntryMethods';
import Toelichting from './ToelichtingComponent';
export default {
    props: ['item','lang'],
    mounted() {
        console.log('kostenplaatsnummer component mounted.');
        this.set_input_value();
    },
    computed: {
        naamTranslated: language.naamTranslated,
        toelichtingTranslated: language.toelichtingTranslated
    },
    methods: {
        update_value: function(event){
            let currentObj = this;
            let store = this.$store;
            if(aanmeldingEntryMethods.aanmeldingEntryExists(this)){
                this.aanmeldingEntry = aanmeldingEntryMethods.getAanmeldingEntry(this);
                this.aanmeldingEntry.value = this.kostenplaatsnummer;
                store.dispatch('updateAanmeldingEntry',this.aanmeldingEntry);
                window.setTimeout(function() {
                    currentObj.aanmeldingEntry.value = currentObj.kostenplaatsnummer;
                    store.dispatch('updateAanmeldingEntry',currentObj.aanmeldingEntry);
                },2000);
                return;
            };
            aanmeldingEntryMethods.createAanmeldingEntry(this,this.kostenplaatsnummer);
        },
        set_input_value: function(){
            let currentObj = this;
            let store = this.$store;
            if(aanmeldingEntryMethods.aanmeldingEntryExists(this)){
                this.aanmeldingEntry = aanmeldingEntryMethods.getAanmeldingEntry(this);
                this.kostenplaatsnummer = this.aanmeldingEntry.value;
                return;
            };
            this.kostenplaatsnummer = '';
        },
        set_default_value: function(){
            if(this.kostenplaatsnummer!=''){
                return;
            }
            this.kostenplaatsnummer = this.item.default_value;
            this.update_value();
        }
    },
    data() {
        return {
            kostenplaatsnummer: ''
        }
    },
    created() {
        this.$store.watch(
            (state, getters) => getters.getAanmeldEntries.models,
            () => {
                this.set_input_value();
            },
        );
        this.$store.watch(
            (state, getters) => getters.getCurrentSessieId,
            () => {
                this.set_input_value();
            }
        );
    },
    components: {
        Toelichting
    }
}
</script>
