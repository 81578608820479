<template>
    <vueVimeoPlayer ref="player"  :video-id="vimeo_video_id"  @ready="onReady"  :autoplay="autoPlayBool" :controls="controls" :loop="loop" :options="{ responsive: true, muted:'muted' }"></vueVimeoPlayer>
</template>

<script>
    import { vueVimeoPlayer } from 'vue-vimeo-player'
    export default {
        mounted() {
            console.log('video mounted.');
            let currentObj = this;
        },
        props: ['vimeo_video_id','autoplay'],
        data() {
            return {
                height: 500,
                options: {},
                playerReady: false,
                controls: true,
                loop: true,
            }
        },
        computed: {
            autoPlayBool: function(){
                if(this.autoplay==1){
                    return true;
                }
                return  false ;
            }
        },
        methods: {
            onReady() {
                let currentObj = this;
                this.playerReady = true;
            }


        },
        components: {
            vueVimeoPlayer
        }
      }
</script>
