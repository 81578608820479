<template>
    <span><Modal :message=message></Modal></span>
</template>

<script>
    import Modal from './ModalComponent';
    export default {
        mounted() {
            console.log('init message modal');
        },
        props:['message'],
        components: { Modal }
    }
</script>