<template>
<div class="grid grid-cols-12">
  	<div v-on:click="switchToSession" v-bind:class="getClass()">{{ session.naam_compleet }}
    </div>
    <div v-if="moreThanOneSessionAndNotCurrent" class="col-span-1 shoppingcart_div_session" v-on:click="removeSession">
      <div class="remove text-right">
        <i class="mdi mdi-close-box  text-project-red" ></i>
      </div>
    </div>
</div>
</template>
<script>
	export default {    	
        mounted() {
            console.log('Session Naam Compleet mounted.');
        },
        props: ['session'],
        methods: {
            switchToSession: function(){
              let currentObj = this;
              let store = this.$store;
              if(currentObj.session.current){
                return;
              }
              store.dispatch('switchSession',currentObj.session.id);
            },
            getClass: function(){
                if(this.session.current){
                    return 'col-span-12 klantRow bg-project-red border border-project-red rounded text-white px-3.5 ';
                }
                return 'col-span-11 klantRow  bg-zinc-400 border border-zinc-400 hover:bg-project-red hover:border-project-red rounded text-white px-3.5 cursor-pointer';
            },
            removeSession: function(){
              let store = this.$store;
              store.dispatch('removeSession',{sessie_id:this.session.id,override:false});
            }   
        },
        computed: {
          moreThanOneSessionAndNotCurrent: function(){
            let store = this.$store;
            let sessions = store.getters.getSessions;
            if(this.session.current){
              return false;
            }
            if(sessions.length>1){
              return true;
            }
            return false;
          }
        }
    }
</script>