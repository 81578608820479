<template>
     <div class="container">

        <!-- Modal -->
        <div class="modal1 mfp-hide fadeIn fast" id="ChooseEventModal">

            <div class="card noborder divcenter" style="max-width: 500px;">
                <img src="/images/aanmelding/deelnemer.jpg" class="card-img-top" alt="Image">
                <a href="#" class="h4 text-muted-50" onClick="$.magnificPopup.close();return false;"><i class="icon-line-circle-cross" style="position:absolute;right:5px"></i></a>
                <div class="card-body p-4">
                  <form @submit="formSubmit" id="subsymposia_form">
                  <h3 class="card-title font-body">{{ $t('aanmelding.Kies uw bijeenkomst') }}</h3>

                      <div class="form-process"></div>
                      <div class="col-12 form-group">
                        <div class="list-group">
                          <SubSymposium v-for="subsymposium in items.models" 
                                      :key="subsymposium.id"
                                      :subsymposium="subsymposium">

                          </SubSymposium>
                        </div>
                      </div>

                      <div class="col-12 hidden">
                        <input type="text" id="modal-feedback-botcheck" name="modal-feedback-botcheck" value="" />
                      </div>
                      <div class="col-12">
                        <button type="submit" name="modal-feedback-submit" class="btn btn-success btn-block">{{ $t('aanmelding.Verstuur') }}</button>
                      </div>
                  </form>
                 </div>
            </div>
        </div>                  
    </div>
</template>

<script type = "text/javascript">
        import SubSymposium from './SubSymposiumComponent';
        import AanmeldingModalMethods from '../methods/AanmeldingModalMethods';
        export default {
            mounted() {
                console.log('ChooseEventModal mounted.');
                let currentObj = this;
                $(document).ready(function(){
                    AanmeldingModalMethods.openModalGeneric(currentObj,'#ChooseEventModal');
                })
            },
            data() {
                let store = this.$store;
                return {
                  items: store.getters.getSubSymposia
                };
            },
            methods: {
                formSubmit(e) {
                    e.preventDefault();
                    let currentObj = this;
                    let store = this.$store;
                    var val = $('input[name="modal-feedback-cleanliness"]:checked', '#subsymposia_form').val();
                    var symposium_naam = $('input[name="modal-feedback-cleanliness"]:checked', '#subsymposia_form').parent().find('label').html();
                    store.dispatch('update_current_session',{symposium_id:val,symposium_naam:symposium_naam});
                    store.dispatch('setAanmeldopties');
                    currentObj.$parent.$router.push({ path: 'registration_form', query: { lang:currentObj.lang }});
                }
            },
            components: {
                SubSymposium
            }
        }
      
</script>