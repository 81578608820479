<template>
<div class="card-body p-4">
  <div class="container " id="template-inline">
        <div class="row">
          <div class="col-sm-12">
            <a class="btn btn-success" v-if="!modify" v-on:click="edit_data"><div>Bewerk</div></a>
          </div>
	      </div>
        <div class="row">
            <div class="col-sm-12">
                <a v-if="modify" class="btn btn-success  " v-on:click="disableEditData"><div>Annuleren</div></a>
                <a v-if="modify"  class="btn btn-success  " v-on:click="submit_user_data"><div>Insturen</div></a>
            </div>
        </div> 
        <div class="row">
          <div class="col-sm-4">
           
            <div class="form-group">
              <h3 style="margin-bottom: 0px;margin-top: 0px;">Gegevens</h3>
 
            </div>
 
            <div class="form-group">
              <div class="label_div">
                Titel 
              </div>
 
              <div>
                <span v-if="!modify">{{ klant.titel}} </span>
                <input v-model="klant.titel" v-if="modify" style="max-width: 200px;" class="required" type="text" value="" name="template-inline-name" v-bind:placeholder="klant.titel"/>
              </div>
 
            </div>
 
            <div class="form-group">
              <div class="label_div">
                Voorletters 
              </div>
 
              <div>
                <span v-if="!modify">{{ klant.voorletters}} </span>
                <input v-model="klant.voorletters" v-if="modify" style="max-width: 200px;" class="required" type="text" value="" name="template-inline-name" v-bind:placeholder="klant.voorletters"/>
              </div>
 
            </div>
 
            <div class="form-group">
              <div class="label_div">
                Voornaam 
              </div>
 
              <div>
                <span v-if="!modify">{{ klant.voornaam}}</span>
                <input v-model="klant.voornaam" v-if="modify" style="max-width: 200px;" class="required" type="text" value="" name="template-inline-name" v-bind:placeholder="klant.voornaam"/> 
              </div>
 
            </div>
 
            <div class="form-group">
              <div class="label_div">
                Tussenvoegsel 
              </div>
 
              <div>
                <span v-if="!modify">{{ klant.tussenvoegsel}} </span>
                <input v-model="klant.tussenvoegsel" v-if="modify" style="max-width: 200px;" class="required" type="text" value="" name="template-inline-name" v-bind:placeholder="klant.tussenvoegsel"/>
              </div>
 
            </div>
 
            <div class="form-group">
              <div class="label_div">
                Achternaam 
              </div>
 
              <div>
                <span v-if="!modify">{{ klant.naam}} </span>
                <input v-model="klant.naam" v-if="modify" style="max-width: 200px;" class="required" type="text" value="" name="template-inline-name" v-bind:placeholder="klant.naam"/>
              </div>
 
            </div>
 
            <div class="form-group">
              <div class="label_div">
                Achtervoegsel 
              </div>
 
              <div>
                <span v-if="!modify">{{ klant.achtervoegsel}}</span>
                <input v-model="klant.achtervoegsel" v-if="modify" style="max-width: 200px;" class="required" type="text" value="" name="template-inline-name" v-bind:placeholder="klant.achtervoegsel"/>
              </div>
 
            </div>
 
            <div class="form-group">
              <div class="label_div">
                Geslacht 
              </div>
 
              <div>
                <span v-if="!modify">{{ klant.geslacht}} </span>
                <input v-model="klant.geslacht" v-if="modify" style="max-width: 200px;" class="required" type="text" value="" name="template-inline-name" v-bind:placeholder="klant.geslacht"/>
              </div>
 
            </div>
 
            <div class="form-group">
              <div class="label_div">
                Geboortedatum 
              </div>
 
              <div>
                <span v-if="!modify">{{ klant.geboortedatum}} </span>
                <input v-model="klant.geboortedatum" v-if="modify" style="max-width: 200px;" class="required" type="text" value="" name="template-inline-name" v-bind:placeholder="klant.geboortedatum"/>
              </div>
 
            </div>
 
            <div class="form-group">
              <div class="label_div">
                Uniek_Dierenartsen_Nummer 
              </div>
 
              <div>
                <span v-if="!modify">{{ klant.identificatienummer}} </span>
                <input v-model="klant.identificatienummer" v-if="modify" style="max-width: 200px;" class="required" type="text" value="" name="template-inline-name" v-bind:placeholder="klant.identificatienummer"/>
              </div>
 
            </div>
 
          </div>
 
          <div class="col-sm-4">
            <div class="form-group">
              <h3 style="margin-bottom: 0px;margin-top: 0px;">Zakelijk</h3>
 
            </div>
 
            <div class="form-group">
              <div class="label_div">
                Praktijk 
              </div>
 
              <div>
                <span v-if="!modify">{{ klant.praktijk}} </span>
                <input v-model="klant.praktijk" v-if="modify" style="max-width: 200px;" class="required" type="text" value="" name="template-inline-name" v-bind:placeholder="klant.praktijk"/>
              </div>
 
            </div>
 
            <div class="form-group">
              <div class="label_div">
                Adres_praktijk 
              </div>
 
              <div>
                <span v-if="!modify">{{ klant.adres}} </span>
                <input v-model="klant.adres" v-if="modify" style="max-width: 200px;" class="required" type="text" value="" name="template-inline-name" v-bind:placeholder="klant.adres"/>
              </div>
 
            </div>
 
            <div class="form-group">
              <div class="label_div">
                Postcode 
              </div>
 
              <div>
                <span v-if="!modify">{{ klant.postcode}} </span>
                <input v-model="klant.postcode" v-if="modify" style="max-width: 200px;" class="required" type="text" value="" name="template-inline-name" v-bind:placeholder="klant.postcode"/>
              </div>
 
            </div>
 
            <div class="form-group">
              <div class="label_div">
                Plaats 
              </div>
 
              <div>
                <span v-if="!modify">{{ klant.plaats}} </span>
                <input v-model="klant.plaats" v-if="modify" style="max-width: 200px;" class="required" type="text" value="" name="template-inline-name" v-bind:placeholder="klant.plaats"/>
              </div>
 
            </div>
 
            <div class="form-group">
              <div class="label_div">
                Telefoonnummer 
              </div>
 
              <div>
                <span v-if="!modify">{{ klant.telefoon}} </span>
                <input v-model="klant.telefoon" v-if="modify" style="max-width: 200px;" class="required" type="text" value="" name="template-inline-name" v-bind:placeholder="klant.telefoon"/>
              </div>
 
            </div>
 
            <div class="form-group">
              <div class="label_div">
                Email 
              </div>
 
              <div>
                <span v-if="!modify">{{ klant.email}} </span>
                <input v-model="klant.email" v-if="modify" style="max-width: 200px;" class="required" type="text" value="" name="template-inline-name" v-bind:placeholder="klant.email"/>
              </div>
 
            </div>
 
          </div>
 
          <div class="col-sm-4">
            <div class="form-group">
              <h3 style="margin-bottom: 0px;margin-top: 0px;">Prive</h3>
 
            </div>
 
            <div class="form-group">
              <div class="label_div">
                Adres 
              </div>
 
              <div>
                <span v-if="!modify">{{ klant.adres_prive}} </span>
                <input v-model="klant.adres_prive" v-if="modify" style="max-width: 200px;" class="required" type="text" value="" name="template-inline-name" v-bind:placeholder="klant.adres_prive"/>
              </div>
 
            </div>
 
            <div class="form-group">
              <div class="label_div">
                Postcode 
              </div>
 
              <div>
                <span v-if="!modify">{{ klant.postcode_prive}} </span>
                <input v-model="klant.postcode_prive" v-if="modify" style="max-width: 200px;" class="required" type="text" value="" name="template-inline-name" v-bind:placeholder="klant.postcode_prive"/>
              </div>
 
            </div>
 
            <div class="form-group">
              <div class="label_div">
                Plaats 
              </div>
 
              <div>
                <span v-if="!modify">{{ klant.plaats_prive}} </span>
                <input v-model="klant.plaats_prive" v-if="modify" style="max-width: 200px;" class="required" type="text" value="" name="template-inline-name" v-bind:placeholder="klant.plaats_prive"/>
              </div>
 
            </div>
 
            <div class="form-group">
              <div class="label_div">
                Telefoonnummer 
              </div>
 
              <div>
                <span v-if="!modify">{{ klant.telefoon_prive}} </span>
                <input v-model="klant.telefoon_prive" v-if="modify" style="max-width: 200px;" class="required" type="text" value="" name="template-inline-name" v-bind:placeholder="klant.telefoon_prive"/>
              </div>
 
            </div>
 
            <div class="form-group">
              <div class="col-sm-12">
                &nbsp; 
              </div>
 
            </div>
 
            
            <div class="form-group">
              <div class="col-sm-12">
              </div>
 
            </div>
 
          </div>
 
        </div>
	</div>
</div>
</template>

<script>
    export default {    	
        mounted() {
            console.log('User Record mounted.');
            this.getKlantData();
        },
        data() {
            let store = this.$store;
            return {
              klant : {},
              modify : false
            };
        },
        methods: {
          getKlantData: function(){
             let currentObj = this;
             axios.post('/ajax/get_user_data', {}).then(function (response) {
                    if(response.data.msg=='fail'){
                      console.dir(response);
                    }else{
                      currentObj.klant = response.data.klant;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
          },
          edit_data: function(){
              this.modify = true;
          },
          disableEditData: function(){
              this.modify = false;
          },
          submit_user_data: function(){
              let currentObj = this;
              axios.post('/ajax/set_user_data', currentObj.klant).then(function (response) {
                    if(response.data.msg=='fail'){
                      console.dir(response);
                    }else{
                      currentObj.disableEditData();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
          }
        }

    }
</script>	