<template>
    <span>
    	<div class="grid grid-cols-12 shoppingcart_item_row" >
    		<div class="col-span-9 label_div shoppingcart_div px-3.5" ><span v-html="titel"></span> {{ item.value }}
            </div>
    		<div class="col-span-2 d-none d-sm-inline  shoppingcart_div"><span v-if="item_valid">€ {{ item.voucher_value }}</span></div>
    		<div class="col-span-1 shoppingcart_div">
    			<div class="remove text-right" v-on:click="removeItem">
    				<i class="mdi mdi-close-box text-project-red" ></i>
    			</div>
    		</div>
    	</div>
        <div v-if="item_invalid" class="grid grid-cols-12 shoppingcart_item_row" >
            <div  class="col-span-12 label_div shoppingcart_div px-3.5" ><span class="text-danger">{{ item.voucher_message }}</span>
            </div>
        </div>
    </span>
</template>
<script>
	import aanmeldingEntryMethods from '../../methods/AanmeldingEntryMethods';
	export default {
        mounted() {
            console.log('Shopping Cart Item mounted.');
        },
        props: ['item'],
        computed: {
        	titel: function(){
        		return aanmeldingEntryMethods.getEntryTitel(this);
        	},
            item_invalid: function(){
                if(typeof(this.item.voucher_valid)=='undefined'){
                    return false;
                }
                if(this.item.voucher_valid === false&&this.item.voucher_valid!=undefined){
                    return true;
                }
                return false;
            },
            item_valid: function(){
                if(this.item.voucher_valid === false){
                    return false;
                }
                return true;
            }
        },
        methods: {
        	removeItem: function(){
                let currentObj = this;
        		aanmeldingEntryMethods.deleteShoppingCartItem(this).then(function(){
                    aanmeldingEntryMethods.delay(2000).then(function(){aanmeldingEntryMethods.refreshPrijsEntries(currentObj)});
                });
        	}
        }
    }
</script>
