import { Collection } from 'vue-mc'
import AanmeldingEntry from '../models/AanmeldingEntry'
import _ from 'lodash'

export default class SessionPropertyBagCollection extends Collection {

    model() {
        return AanmeldingEntry;
    }

    add(payload){
        if(this.find({id:payload.id})!=undefined){
            return;
        }
        if(this.find({aanmeldformulier_id:payload.aanmeldformulier_id,sessie_id:payload.sessie_id})!=undefined){
            return;
        }
        return super.add(payload);
    }

    routes() {
        return {
            fetch: '/aanmelding_entry/{id}',
            add: '/aanmelding_entry/add'
            
        }
    }
}
