<template>
	<span v-if="display">
	  <slot ></slot>
	</span>
</template>

<script>

    export default {
        props:['lang'],
        mounted() {
            console.log('translation element mounted.')
        },
        computed: {
            display() {
                if(this.lang == this.$i18n.locale){
                    return true;
                }
                return false;
            }
        }
    }
</script>