<template>
    <div >
        <a href="#" @click="gotoLogin" class="btn btn-success btn-block btn-lg btn-inschrijven"><div>{{ $t('aanmelding.Inschrijven') }}</div></a>
    </div>
</template>

<script>
    import AanmeldingModalMethods from '../methods/AanmeldingModalMethods';
    export default {
        mounted() {
            console.log('AanmeldLink mounted.');
        },
        props:['symposium_id'],
        methods: {
            gotoLogin: function () {
                let currentObj = this;
                AanmeldingModalMethods.gotoLogin(currentObj);
            }
        }
    }
</script>
