<template>
    <div v-bind:issuerId="issuer.id" :class="div_class"><img v-bind:src="issuer.image.size1x"><p>{{ issuer.name }}</p></div>
</template>

<script>
    export default {
    	props: ['issuer','selected_issuer'],
        mounted() {
            console.log('issuer mounted.')
        },
        computed: {
            div_class: function(){
                if(this.selected_issuer){
                    return 'xs:col-span-12 sm:col-span-6 md:col-span-3 lg:col-span-4 issuerDiv selected-issuer-div m-2 cursor-pointer';
                }
                return 'xs:col-span-12 sm:col-span-6 md:col-span-3 lg:col-span-4 issuerDiv m-2 cursor-pointer';
            }
        }
    }
</script>