<template>
	    <div class="form-group row" v-bind:for="item.id">
	    	<div class="col-sm-4">
                <span v-html="naamTranslated">
                </span>
                <Toelichting :item="item"></Toelichting>
	    	</div>
	    	<div class="col-sm-8">
	    		<div>
	    			<el-select v-model="value" placeholder="Select" v-on:change="update_value">
					    <el-option
					      v-for="item in Options()"
					      :key="item.value"
					      :label="item.label"
					      :value="item.value">
					    </el-option>
					  </el-select>
	    		</div>

	    	</div>
	    </div>
</template>

<script>
	import language from '../../methods/lang';
	import _ from 'lodash';
	import aanmeldingEntryMethods from '../../methods/AanmeldingEntryMethods';
    import Toelichting from './ToelichtingComponent';
    export default {
    	props: ['item','lang'],
        mounted() {
            console.log('selectcomponent mounted.');
            this.set_select_value();
            let currentObj = this;
            this.$root.$on('handle_select', () => {
                currentObj.update_value();
            });
        },
        computed: {
		    naamTranslated: language.naamTranslated,
		    toelichtingTranslated: language.toelichtingTranslated
        },
        methods: {
        	Options(){
        		let temp =  this.item.opties.split(';');
        		let optionArr = [];
        		_.forEach(temp,function(value){
        			optionArr.push({'value':value,'label':value});
        		});
        		return optionArr;
        	},
        	update_value: function(event){
        		let currentObj = this;
        		let store = this.$store;
        		if(aanmeldingEntryMethods.aanmeldingEntryExists(this)){
        			this.aanmeldingEntry = aanmeldingEntryMethods.getAanmeldingEntry(this);
                    if(this.value=='Maak uw keuze'||this.value=='Make your choice'){
                        aanmeldingEntryMethods.deleteAanmeldingEntry(this);
                        return;
                    }
        			this.aanmeldingEntry.value = this.value;
        			store.dispatch('updateAanmeldingEntry',this.aanmeldingEntry);
        			return;
        		};
                if(this.value=='Maak uw keuze'||this.value=='Make your choice'){
                    return;
                }
        		aanmeldingEntryMethods.createAanmeldingEntry(this,this.value);
        	},
        	set_select_value: function(){
        		let currentObj = this;
        		let store = this.$store;
        		if(aanmeldingEntryMethods.aanmeldingEntryExists(this)){
        			this.aanmeldingEntry = aanmeldingEntryMethods.getAanmeldingEntry(this);
        			this.value = this.aanmeldingEntry.value;
        			return;
        		};
        		this.value = _.head(this.Options()).value;
        	}
        },
        data() {
	      return {
	        value: _.head(this.Options()).value
	      }
	    },
        components: {
            Toelichting
        },
        created() {
            this.$store.watch(
              (state, getters) => getters.getAanmeldEntries.models,
              () => {
                this.set_select_value();
              },
            );
            this.$store.watch(
              (state, getters) => getters.getCurrentSessieId,
              () => {
                this.set_select_value();
              }
            );
        }
    }
</script>
