<template>
	<div class="language-selector-aanmelding-div">
		<a  v-on:click.stop.prevent="changeLanguage('nl')"> 
			<img :src="image_nl.url" class="lang_img" width="16" height="16"> 
		 </a> 
		<a v-on:click.stop.prevent="changeLanguage('en')"> 
			<img :src="image_en.url" class="lang_img" width="16" height="16"> 
		</a>  	
	</div>
</template>

<script>
	import LanguageMethods from '../methods/LanguageMethods';
    export default {
        mounted() {
            console.log('Language picker mounted.');
        },
        methods: {
            changeLanguage: function (lang) {
            	if(lang==this.$i18n.locale){
            		return '';
            	}
            	LanguageMethods.switchLang(lang,this.$store,this.$i18n);
                
            },
            isActive: function(lang){
            	if(lang==this.$i18n.locale){
            		return 'active';
            	}
            }
        },
        data() {
            return {
              image_nl: { url: '/images/nl.svg' },
              image_en: { url: '/images/gb.svg' },
            };
        }
        
    }
</script>