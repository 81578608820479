<template>
	<div v-if="items.length>0" class="shoppingcart_div">
    <div class="grid grid-cols-12 ">
      <div class="col-span-12">
        <SessionNaamCompleet  :session="session" :key="session.id"></SessionNaamCompleet>
      </div>
    </div>
    <div class="grid grid-cols-12 py-1">
      <div v-if="items.length==0" class="col-span-12 px-3.5">
          {{ $t('aanmelding.Nog geen gegevens') }}
      </div>
    </div>
		<BillItemFactory v-for="item in items"  :item="item" :lang="lang"  :key="item.id"></BillItemFactory>
	</div>
</template>

<script>
	import BillItemFactory from './BillItemFactoryComponent';
  import SessionNaamCompleet from './SessionNaamCompleetComponent';
  import language from '../../methods/lang';
    export default {    	
        mounted() {
            console.log('Shopping Cart Session mounted.');
        },
        props: ['session'],
        data() {
            let store = this.$store;
            let currentObj = this;
            //let itemsList = store.getters.getAanmeldEntries.filter({'sessie_id':this.session.id}).models;
            let itemsList = store.getters.getAanmeldEntries.filter(function(o) { return (o.sessie_id == currentObj.session.id && (o.soort == 'prijs'||o.soort == 'prijs_6'||o.soort == 'voucher')) }).models;
            return {
              lang: store.getters.getLang,
              items: itemsList
            };
        },
        components: {
        	BillItemFactory,
          SessionNaamCompleet
        },
        created() {
            let currentObj = this;
            this.$store.watch(
              (state, getters) => getters.getAanmeldEntries.models,
              () => {
                currentObj.items = currentObj.$store.getters.getAanmeldEntries.filter(function(o) { return (o.sessie_id == currentObj.session.id && (o.soort == 'prijs'||o.soort == 'prijs_6'||o.soort == 'voucher')) }).models;
              },
            );
        }

    }
</script>	