<template>
    <span>
    	<div class="row shoppingcart_item_row" >
            <div class="col-sm-6 shoppingcart_div label_div"> {{ titel }}</div>
    		<div class="col-sm-5 shoppingcart_div "> {{ item.value }}</div>
    		<div class="col-sm-1 shoppingcart_div">
    			<div class="remove text-right" v-on:click="removeItem">
    				<i class="icon-remove" ></i>
    			</div>
    		</div>
    	</div>
    </span>
</template>
<script>
	import aanmeldingEntryMethods from '../../methods/AanmeldingEntryMethods';
	export default {
        mounted() {
            console.log('Shopping Cart Kostenplaats Item mounted.');
        },
        props: ['item'],
        computed: {
        	titel: function(){
        		return aanmeldingEntryMethods.getEntryTitel(this);
        	}
        },
        methods: {
        	removeItem: function(){
        		aanmeldingEntryMethods.deleteShoppingCartItem(this);
        	}
        }
    }
</script>
