import Vue from 'vue';
import VueI18n from 'vue-i18n';
//import nl from '../lang/nl.json';

Vue.use(VueI18n);

export const i18n = new VueI18n({
	locale: 'nl',
	fallbackLocale: 'nl',
	messages: {}
});

