<template>
	    <div class="form-group row" v-bind:for="item.id">
	    	<div class="col-sm-4">
	    		<span v-html="naamTranslated">
	    		</span>
	    		<Toelichting :item="item"></Toelichting>
	    	</div>
	    	<div class="col-sm-8">
	    		<div>
					  <el-radio-group v-model="checkedValue" v-on:input="clickCheckbox">
						  <el-radio v-for="item in Options()"
					      :key="item.value"
					      :label="item.label"
					      :value="item.value"
					      ><span v-html="item.value"></span><br/></el-radio>
					  </el-radio-group>
	    		</div>

	    	</div>
	    </div>
</template>

<script>
	import language from '../../methods/lang';
	import aanmeldingEntryMethods from '../../methods/AanmeldingEntryMethods';
	import Toelichting from './ToelichtingComponent';
	import _ from 'lodash';
    export default {
    	props: ['item','lang'],
        mounted() {
            console.log('radiocomponent mounted.');
            this.setChecked();
        },
        computed: {
		    naamTranslated: language.naamTranslated,
		    toelichtingTranslated: language.toelichtingTranslated
        },
        methods: {
        	Options(){
        		let temp =  this.item.opties.split(';');
        		let optionArr = [];
        		_.forEach(temp,function(value){
        			optionArr.push({'value':value.trim(),'label':value.trim()});
        		});
        		return optionArr;
        	},
        	clickCheckbox: function(data){
        		if(aanmeldingEntryMethods.aanmeldingEntryExists(this)){
        			aanmeldingEntryMethods.deleteAanmeldingEntry(this);
        		}
        		aanmeldingEntryMethods.createAanmeldingEntry(this,data);
        	},
        	setChecked: function(){
        		let currentObj = this;
        		let store = this.$store;
        		if(aanmeldingEntryMethods.aanmeldingEntryExists(this)){
        			this.aanmeldingEntry = aanmeldingEntryMethods.getAanmeldingEntry(this);
        			this.checkedValue = this.aanmeldingEntry.value;
        			return;
        		}
        		this.checkedValue = '';
        	}
        },
        data() {
	      return {
	        aanmeldingEntry: undefined,
	        checkedValue: ''
	      }
	    },
        created() {
            this.$store.watch(
                (state, getters) => getters.getAanmeldEntries.models,
                () => {
                    this.setChecked();
                },
            );
            this.$store.watch(
                (state, getters) => getters.getCurrentSessieId,
                () => {
                    this.setChecked();
                }
            );
        },
	    components: {
  			Toelichting
	    }
    }
</script>
