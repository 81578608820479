/**
 * First we will load all of this project.php's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

//window.Vue = require('vue');

import Vue from 'vue'
import VueRouter from 'vue-router';
import store from './store';
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/nl';
import '../../themes/theme-proveto/index.css';
import {i18n} from './plugins/i18n.js';
import magnificPopup from 'magnific-popup';
// import {SEMICOLON} from "./semicolon";


Vue.use(VueRouter);
Vue.use(ElementUI, {locale});



var a = Vue.component('aanmelding-init', require('./components/AanmeldingInitComponent.vue').default);
var b = Vue.component('login-modal-ajax', require('./components/LoginModalAjaxVscComponent.vue').default);
var c = Vue.component('choose-participant', require('./components/ChooseParticipantVscComponent.vue').default);
var d = Vue.component('choose-event', require('./components/ChooseEventComponent.vue').default);
var e = Vue.component('modal-container', require('./components/ModalComponent.vue').default);
var f = Vue.component('aanmeld-button', require('./components/AanmeldButtonComponent.vue').default);
var g = Vue.component('registration-form', require('./components/registrationform_vsc/RegistrationFormComponent.vue').default);
var h = Vue.component('registration-confirm', require('./components/RegistrationConfirmModalVscComponent.vue').default);
var i = Vue.component('aanmeld-link', require('./components/AanmeldLinkComponent.vue').default);
var j = Vue.component('login-init', require('./components/LoginInitComponent.vue').default);
var k = Vue.component('user-data', require('./components/UserDataComponent.vue').default);
// var l = Vue.component('video-button', require('./components/VideoButtonComponent.vue').default);
// var m = Vue.component('video-modal', require('./components/VideoModalComponent.vue').default);
var n = Vue.component('password-forgotten-modal',require('./components/PasswordForgottenModalVscComponent.vue').default);
var p = Vue.component('messageModalInit',require('./components/MessageModalInitComponent.vue').default);
// var q = Vue.component('aanmeld-button-home', require('./components/AanmeldButtonHomeComponent.vue').default);
var r = Vue.component('home-init', require('./components/HomeInitComponent.vue').default);
var s = Vue.component('aanmelding_afronden', require('./components/AanmeldingAfrondenVscComponent.vue').default);
var t = Vue.component('language-switch', require('./components/LanguageSelectorAanmeldingComponent.vue').default);
var u = Vue.component('label-div-tag', require('./components/LabelDivComponent.vue').default);
// var v = Vue.component('video-button-small', require('./components/VideoButtonSmallComponent.vue').default);
var w = Vue.component('translation-element', require('./components/TranslationElementComponent.vue').default);
var x = Vue.component('register-user', require('./components/RegisterUserVscComponent.vue').default);
// var y = Vue.component('quiz-modal', require('./components/QuizModalComponent.vue').default);
// var z = Vue.component('quiz-result-modal', require('./components/QuizResultModalComponent.vue').default);
// var aa = Vue.component('order-video-button', require('./components/OrderVideoButtonComponent.vue').default);
// var ab = Vue.component('order-video-button-small', require('./components/OrderVideoButtonSmallComponent.vue').default);
// var ac = Vue.component('proveto-at-home-modal-info', require('./components/OrderVideoModalComponent.vue').default);
// var ad = Vue.component('proveto-at-home-modal-login', require('./components/OrderVideoLoginModalComponent.vue').default);
// var ae = Vue.component('proveto-at-home-modal-bill', require('./components/OrderVideoBillModalComponent.vue').default);
// var af = Vue.component('video_bestelling_afronden', require('./components/VideoBestellenAfrondenComponent.vue').default);
var ag = Vue.component('add_email_user', require('./components/AddEmailUserComponent.vue').default);



const routes = [
    { path: '/', component: {template: '<div></div>'} },
    { path: '/login_ajax_aanmelding', component: b},
    { path: '/choose_participant', component: c},
    { path: '/choose_event', component: d},
    { path: '/registration_form', component: g, props: true},
    { path: '/registration_confirm', component: h, props: true},
    { path: '/login_link', component: j},
    // { path: '/video_modal', name:'video_modal', component: m,  props: true},
    { path: '/password_forgotten', component: n},
    { path: '/message', name: 'message', component: e, props: true},
    { path: '/aanmelding_afronden', component: s, props: true},
    { path: '/register_user', name: 'register_user', component: x, props: true},
    // { path: '/quiz_modal', name: 'quiz_modal', component: y, props: true},
    // { path: '/quiz_result_modal', name: 'quiz_result_modal', component: z, props: true},
    // { path: '/proveto_at_home_modal_info', name: 'proveto_at_home_modal_info', component: ac, props:true},
    // { path: '/proveto_at_home_modal_login', name: 'proveto_at_home_modal_login', component: ad, props:true},
    // { path: '/proveto_at_home_modal_bill', name: 'proveto_at_home_modal_bill', component: ae, props:true},
    // { path: '/video_bestelling_afronden', name: 'video_bestelling_afronden', component: af, props: true},
    { path: '/add_email/:email_token', component: ag, props: true},
]

const router = new VueRouter({
    mode:'hash',
    routes
})

router.afterEach((to, from) => {
    if(to.query.hasOwnProperty('section')){
        var elmnt = document.getElementById(to.query.section);
        elmnt.scrollIntoView();
    }
})

if(document.getElementById('app')){
    const placeholder = new Vue({
        el: '#app',
        router,
        store,
        i18n,
        component: {
            'home-init': r
        }
    });
}

// const menu = new Vue({
//     el: '#primary-menu',
//     router,
//     store,
//     components: {
//       'aanmeld-link': f
//     }
// });

if(document.getElementById('aanmeld_button1')){
    const aanmeld_button1 = new Vue({
        el: '#aanmeld_button1',
        router,
        store,
        i18n,
        components: {
            'aanmeld-button': f
        }
    })
}
if(document.getElementById('aanmeld_button2')){
    const aanmeld_button2 = new Vue({
        el: '#aanmeld_button2',
        router,
        store,
        i18n,
        components: {
            'aanmeld-button': f
        }
    })
}


if(document.getElementsByClassName('aanmeld-button').length){
    const elements = document.getElementsByClassName('aanmeld-button');
    for(var i = 0; i < elements.length; i++)
        new Vue({
            el: elements[i],
            router,
            store,
            i18n,
            components: {
                'aanmeld-button-home': q
            }
        })
}

if(document.getElementById('user_data')){
    const user_data = new Vue({
        el: '#user_data',
        router,
        store,
        components: {
            'user-data': k
        }
    })
}

// if($('.mijn_videos_container').length){
//   $('.mijn_videos_container').find('.mijn-video-grid-item').each(function(){
//       const video_button = new Vue({
//           el: '#'+$(this).attr('id'),
//           router,
//           store,
//           i18n,
//           components: {
//           'video-button': l,
//           'video-button-small':v
//         }
//       })
//   })
//
// }
//
// if($('.proveto_at_home_container').length){
//   $('.proveto_at_home_container').find('.proveto-at-home-grid-item').each(function(){
//       const order_video_button = new Vue({
//           el: '#'+$(this).attr('id'),
//           router,
//           store,
//           i18n,
//           components: {
//           'order-video-button': aa,
//           'order-video-button-small': ab
//         }
//       })
//   })
//
// }

if(document.getElementById('password_forgotten_div')){
    const passwordForgotten = new Vue({
        el: '#password_forgotten_div',
        router
    })
}

if(document.getElementById('register_user_div_home')){
    const registerUser = new Vue({
        el: '#register_user_div_home',
        router
    })
}

if(document.getElementById('messageModalInitContainer')){
    const messageModalInit = new Vue({
        el: '#messageModalInitContainer',
        router,
        i18n,
        components: {
            'message-modal-init': p
        }
    })
}

if(document.getElementById('language-selector-div')){
    const language_selector = new Vue({
        name: 'language-selector',
        el: '#language-selector-div',
        router,
        store,
        i18n,
        components: {
            'language-selector': t
        }
    })
}

if(document.getElementById('primary-menu')){
    const language_selector2 = new Vue({
        name: 'language-selector',
        el: '#primary-menu',
        router,
        store,
        i18n,
        components: {
            'language-selector': t
        }
    })
}

if(document.getElementsByClassName('trans-label-div').length){
    document.getElementsByClassName('trans-label-div').each(function(){
        let currentObj = this;
        const label_div = new Vue({
            el: '#'+currentObj.getAttribute('id'),
            router,
            store,
            i18n,
            components: {
                'label-div': u
            }
        })
    })

}

if(document.getElementsByClassName('trans-element').length){
    document.getElementsByClassName('trans-element').each(function(){
        let currentObj = this;
        const label_div = new Vue({
            el: '#'+currentObj.getAttribute('id'),
            i18n,
            store,
            components: {
                'trans-element': w
            }
        })
    })

}