import { Model } from 'vue-mc'
import { required } from 'vue-mc/validation'

export default class AanmeldingSession extends Model {

    defaults() {
        return {
            id: null,
            klant_id: '',
            naam_compleet: '',
            symposium_id: '',
            symposium_naam: '',
            aanmelding_id:'',
            current: false
        }
    }

    options() {
        return {
            useFirstErrorOnly: true,
            methods: {
                modify: 'PUT'
            }
        }
    }

    validation() {
        return ;
    }

    mutations() {
        return {
            current: Boolean,
        }
    }

    routes() {
        return {
            save:   '/aanmelding_session',
            delete: '/aanmelding_session/{id}',
            update: '/aanmelding_session/{id}',
            modify: '/aanmelding_session/{id}'
        }
    }

    modify() {
        let method = this.getOption('methods.modify');
        let route  = this.getRoute('modify');
        let params = this.getRouteParameters();
        let url    = this.getURL(route, params);
        let data   = {  id: this.id,
                        klant_id: this.klant_id,
                        naam_compleet: this.naam_compleet,
                        symposium_id: this.symposium_id,
                        symposium_naam: this.symposium_naam,
                        current: this.current
                    };
        
        return this.createRequest({method, url, data}).send();
    }
}
